$midnight-08: rgba(30, 42, 52, 0.8);
$full-black-006: rgba(0, 0, 0, 0.06);
$black-pearl: #1e2a32;
$pale-cornflower-blue: #b2d7f8;
$gainsboro: #d8d8d8;
$full-white: #fff;
$picton-blue: #619ecc;
$light-grey: #d0d0d0;
$persian-green: #00a19a;
$steel-blue: #4d8bbb;
$sapphire: #053b63;
$cinnabar: #eb5835;
$nobel: #999;
$concrete: #f2f2f2;
$white-smoke: #eee;
$lynx-white: #f7f7f7;
$shuttle-grey: #5a6268;
$raven: #6d757b;
$snow: #fbfbfb;
$red-berry: #721c24;
$pale-pink: #f8d7da;
$azalea: #f5c6cb;
$grey-chateau: #979ea3;
$dark-orange: #f99100;
$dark-cerulean: #024679;
$gainsboro-2: #dbdbdb;
$coconut: #fefefe;
$horizon: #618198;
$endeavour: #1a5d8f;
$gull-grey: #a8aeb2;
$gainsboro-3: #dedede;
$snow-2: #fcfcfc;
$whisper: #e7e7e7;
$heather: #a0b7ca;
$light-grey-2: #d2d2d2;
$gainsboro-4: #ddd;
$endeavour-2: #175a8d;
$dodger-blue: #3686f7;
$elephant: #283742;
$grey: #767676;
$gainsboro-5: #e0e0e0;
$whisper-2: #e5e5e5;
$cerulean: #036cb4;
$whisper-3: #eaeaea;
$blue-bayoux: #647179;
$whisper-4: #ececec;
$nobel: #989595;

$imagesPath: "../../images";
$fontsPath: "../../fonts";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.agreement + .errors {
  margin-top: 10px;
}

.co {
  width: 100%;

  h4 {
    position: relative;
    display: block;

    &:hover {
      .dropdown {
        display: block;
      }
    }
  }

  .dropdown {
    position: absolute;
    top: 100%;
    background: #fff;
    left: -5px;
    display: none;
    width: 100%;
    z-index: 999;
    box-shadow: 0px 2px 15px -5px rgba(0, 0, 0, 0.15);

    a {
      display: block;
      cursor: pointer;
      padding: 10px 5px;
    }
  }
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  overflow-x: hidden;
}

::-webkit-input-placeholder {
  color: inherit;
}

span + .radio-tabs {
  margin-top: 15px;
}

::-moz-placeholder {
  color: inherit;
  opacity: 1;
}

input[type="tel"],
input[type="url"],
input[type="password"],
input[type="text"],
input[type="email"],
input[type="reset"],
input[type="button"],
input[type="submit"],
button,
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

img {
  vertical-align: middle;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a[href],
label[for],
select,
input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
}

button,
input[type="button"],
input[type="image"],
input[type="reset"],
input[type="submit"] {
  padding: 0;
  overflow: visible;
  cursor: pointer;
}

button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="image"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner {
  border: 0;
}

.hide {
  position: absolute !important;
  left: -9999em !important;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

del {
  text-decoration: line-through;
}

th,
td {
  vertical-align: top;
}

th {
  font-weight: normal;
  text-align: left;
}

address,
cite,
dfn {
  font-style: normal;
}

abbr,
acronym {
  border-bottom: 1px dotted $nobel;
  cursor: help;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

textarea {
  overflow: auto;
}

html {
  height: 100%;
}

/* Normalized Styles
------------------------------------------------------------------------------*/

body {
  font: 16px/24px "avenirnext", sans-serif;
  background: $concrete;
  -webkit-text-size-adjust: none;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100%;
  color: $black-pearl;
}

input,
textarea,
select {
  // outline: none;
  font-family: "avenirnext", sans-serif;
}

a {
  text-decoration: none;
  transition: all 0.3s ease;
  color: inherit;
}

a:hover {
  color: $picton-blue;
}

/* Container
------------------------------------------------------------------------------*/

.container {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

/* Header
------------------------------------------------------------------------------*/

.header {
  height: 70px;
  box-shadow: 0 1px 2px $full-black-006;
  background-color: $full-white;
}

.header .logo {
  float: left;
  margin: 18px 0 0;
}

.header h1 {
  color: $black-pearl;
  border-left: 1px solid $light-grey;
  height: 26px;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  float: left;
  margin: 23px 0 0 18px;
  padding-left: 19px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.header .container {
  width: auto;
  padding: 0 21px;
}

.header .main-nav {
  float: right;
  height: 70px;

  @media (max-width: 1023px) {
    &.mobile-show {
      display: block;
      // transform: translateX(0%);
      opacity: 1;
      transition: all 0.2s ease-in;
      position: fixed;
    }
  }
}

.header .main-nav > ul {
  float: left;
  padding-right: 12px;
}

.header .main-nav > ul li {
  float: left;
}

.header .main-nav > ul a {
  display: block;
  color: $black-pearl;
  font-size: 16px;
  padding: 0 18px;
  font-weight: 500;
  line-height: 68px;
  height: 70px;
  padding-top: 2px;
  border-bottom: 2px solid transparent;
}

.header .main-nav > ul a.active,
.header .main-nav > ul a:hover {
  border-color: $black-pearl;
}

.header .main-nav .dropdown {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 999;
  width: 233px;
  background-color: $full-white;

  .switch-accounts {
    border-bottom: 1px solid $white-smoke;
    padding-bottom: 16px;
    padding-top: 20px;
  }
}

.header .main-nav .dropdown label {
  padding: 0;
  margin: 0 20px 12px;
  line-height: normal;
}

.header .main-nav .dropdown .radio label {
  margin-bottom: 10px;
}

.header .main-nav .dropdown ul {
  padding: 10px 0;
}

.header .main-nav .dropdown li {
  padding: 8px 20px;
}

.header .main-nav .dropdown li a {
  display: block;
  color: $black-pearl;
  font-size: 18px;
  text-transform: none;
  font-weight: 500;
  cursor: pointer;
  line-height: 18px;
}

.header .main-nav .dropdown li a:hover {
  color: $picton-blue;
}

.no-touch .header .main-nav .user:hover .dropdown {
  display: block;
}

.header .main-nav .active .dropdown {
  display: block;
}

.header .main-nav .user {
  float: right;
  height: 70px;
  position: relative;
  margin-right: -20px;
  font-size: 14px;
  color: $black-pearl;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.58px;
  line-height: 70px;
  background-color: $lynx-white;

  @media (min-width: 1023px) {
    &:hover {
      .dropdown {
        display: block;
      }
    }
  }

  @media (max-width: 1023px) {
    &.hover {
      .dropdown {
        display: block;
      }

      .trigger:after {
        transform: rotate(180deg);
      }
    }
  }

  // @media all and (min-width: 1024px) and (max-width: 1050px) {
  //   max-width: 120px;
  // }
}

.header .main-nav {
  @media all and (min-width: 1024px) and (max-width: 1200px) {
    .user {
      max-width: 120px;
    }
  }
}

.header .main-nav .user .trigger {
  display: block;
  cursor: pointer;
  padding: 0 20px 0 24px;
}

.header .main-nav .user .trigger:after {
  content: "";
  display: inline-block;
  background: url("#{$imagesPath}/ico_down.svg") no-repeat 50% 50%;
  width: 20px;
  height: 20px;
  position: relative;
  top: -1px;
  margin: 0 0 0 7px;
  vertical-align: middle;
}

.looking {
  text-align: center;
  color: $shuttle-grey;
  font-size: 18px;
  padding: 19px 0 24px;
  font-weight: 400;
  line-height: 18px;
  font-weight: 400;
}

.looking a {
  color: $black-pearl;
  font-weight: 500;
}

.panel.acc-item {
  text-align: center;
  margin-bottom: 40px;
}

.notice-panel.panel {
  margin-bottom: 25px;

  p {
    padding-bottom: 0;
  }
}

.panel.acc-item .image {
  //padding: 20px 0;
}

.image img {
  max-width: 100%;
  height: auto;
}

.panel.acc-item h3 {
  color: $black-pearl;
  font-family: Roboto, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 24px;
  padding: 26px 0 20px;
}

.panel.acc-item p {
  color: $raven;
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 30px;
  line-height: 32px;
}

.panel.acc-item .bottom {
  padding: 30px;
  margin: 0 -30px -30px;
  overflow: hidden;
  border-radius: 0 0 4px 4px;
  background-color: $white-smoke;
}

.panel.acc-item .bottom .btn {
  display: block;
}

.panel .complete-item {
  text-align: center;
  padding: 40px 0 22px;
}

.panel .complete-item h2 {
  color: $black-pearl;
  font-family: Roboto, sans-serif;
  font-size: 26px;
  font-weight: 400;
  padding-top: 0;
  padding-bottom: 5px;
}

.calc-amount {
  width: 120px;
  text-align: center;
}

.primary-invest-input {
  max-width: 130px;
}

.panel .complete-item p {
  color: $shuttle-grey;
  font-size: 18px;
  padding-bottom: 30px;
  line-height: 31px;
}

.pt-16 {
  padding-top: 16px;
}

.pt-25 {
  padding-top: 25px;
}

.steps {
  margin: auto;
  padding: 44px 0;
}

.steps ul {
  text-align: center;
  display: block;
  margin: 0 -27px;
  font-size: 0;
}

.steps li {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 0 27px;
}

.steps li:before {
  content: "";
  height: 2px;
  position: absolute;
  left: 50%;
  right: 0;
  top: 7px;
  background-color: $light-grey;
}

.steps li:after {
  content: "";
  height: 2px;
  position: absolute;
  left: 0;
  right: 50%;
  top: 7px;
  background-color: $light-grey;
}

.steps li.done:before {
  background: $persian-green;
}

.steps li.done + li:after {
  background: $persian-green;
}

.steps li:first-child:after {
  display: none;
}

.steps li:last-child:before {
  display: none;
}

.steps .dot {
  width: 16px;
  margin: auto;
  border-radius: 50%;
  position: relative;
  z-index: 9;
  height: 16px;
  background-color: $light-grey;
}

.steps .done .dot,
.steps .current .dot {
  width: 26px;
  margin: -5px auto;
  height: 26px;
  box-shadow: inset 0 0 0 3px $snow;
  border: 2px solid $persian-green;
  background-color: $persian-green;
}

.steps h4 {
  color: $black-pearl;
  padding: 13px 0 0;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.page-title {
  text-align: center;
  color: $black-pearl;
  font-family: Roboto, sans-serif;
  padding: 9px 0 4px;
  font-size: 48px;
  line-height: normal;
  font-weight: 300;
}

.gutters-20 {
  margin-left: -10px;
  margin-right: -10px;
}

.gutters-20 > div {
  padding-left: 10px;
  padding-right: 10px;
}

.gutters-40 {
  margin-left: -20px;
  margin-right: -20px;
}

.gutters-40 > div {
  padding-left: 20px;
  padding-right: 20px;
}

.gutters-52 {
  margin-left: -26px;
  margin-right: -26px;
}

.gutters-52 > div {
  padding-left: 26px;
  padding-right: 26px;
}

.gutters-60 {
  margin-left: -40px;
  margin-right: -40px;
}

.gutters-60 > div {
  padding-left: 40px;
  padding-right: 40px;
}

/* Content
------------------------------------------------------------------------------*/

.body {
  padding: 57px 0 42px;
  clear: both;
  -webkit-flex-grow: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

.body.dashboard-content-wrapper {
  padding-top: 30px;
}

h2 {
  color: $black-pearl;
  font-size: 26px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  padding-bottom: 26px;
  line-height: 24px;
}

.checkboxes {
  padding: 17px 0 0;
  margin-bottom: -4px;
}

.text-right {
  text-align: right;
}

.download-link {
  padding-bottom: 23px;
}

.download-link a {
  display: block;
  color: $black-pearl;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-left: 28px;
  padding-bottom: 4px;
  background: url("#{$imagesPath}/ico_pdf.svg") no-repeat 0 2px;
}

.download-link a:hover {
  color: $persian-green;
}

.download-link.v2 a {
  background-image: url("#{$imagesPath}/ico_download.svg");
  background-size: 18px 18px;
}

.panel.p0,
.panel.loan-list {
  padding: 0;
}

.panel .btn-center {
  padding: 28px;
  text-align: center;
}

.panel .btn-center .btn {
  min-width: 216px;
}

.table table {
  width: 100%;
}

.table .grate {
  width: 12.9%;
}

.table .loan {
  width: 8%;
  padding-right: 3.8%;
  text-align: right;
}

.table .internet {
  width: 13%;
}

.table .funded {
  padding-right: 30px;
  width: 25.4%;
}

.table .risk {
  width: 14%;
  padding-left: 3.8%;
}

.table .htranch {
  width: 10%;
}

.table .duration {
  width: 19%;
  padding: 20px 10px;
}

.table .rate {
  width: 15%;
}

.table .acc {
  width: 14%;
  text-align: right;
}

.table .tranch {
  text-align: center;
}

.table .accr {
  width: 12%;
}

.table .lend {
  width: 13.4%;
}

.table .lend .btn {
  min-width: 0;
  padding: 0 25px;
}

.table td {
  padding: 20px 20px 24px;
  vertical-align: middle;
  line-height: 24px;
  font-size: 16px;
}

.table .amount {
  text-align: right;
  padding-right: 25px;
  width: 19.5%;
}

//commented this for lender dashboard causing problem in single loan table header check if this outcome cause wealth dashboard

// thead .amount {
//   div {
//     text-align: right;
//     justify-content: flex-end !important;
//   }
// }

.table td p.prop-desc {
  font-size: 14px;
}

.table tbody tr:nth-child(even) td {
  background-color: $concrete;
}

.warning {
  margin-bottom: 30px;

  div {
    padding: 20px;
    color: $red-berry;
    background-color: $pale-pink;
    border: 1px solid $azalea;

    h3 {
      font-size: 20px;
      margin-bottom: 10px;
    }

    p {
      font-weight: 200;
    }

    div {
      padding: 0;
      border: 0;
    }
  }
}

.panel p a {
  color: $picton-blue;

  &:hover {
    text-decoration: underline;
  }
}

.table .set {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;

  max-width: 250px;
  min-width: 250px; // Perhaps this won't work globally...
}

.table h4 {
  color: $black-pearl;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  padding-bottom: 2px;
}

.error.mb-30 {
  margin-bottom: 15px;
}

.table td p {
  color: $shuttle-grey;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
}

.table .grey {
  color: $grey-chateau;
}

.table .status {
  display: inline-block;
  width: 14px;
  vertical-align: middle;
  margin-bottom: -3px;
  border-radius: 50%;
  height: 14px;
  background: $persian-green;
  margin-right: 19px;
  flex-shrink: 0;
  position: relative;

  &.grey {
    background: $grey-chateau;
  }

  &.orange {
    background: $dark-orange;
  }

  &.blue {
    background: $dark-cerulean;
  }
}

.small-terms {
  span {
    font-size: 15px !important;
    font-weight: 300 !important;
    padding-top: 3px;
    line-height: 1.4 !important;
  }
}

.btn-small + .btn-small {
  margin-top: 10px;
}

.table .status:hover .tooltip {
  display: block;
}

.table .status .tooltip {
  position: absolute;
  top: 100%;
  width: 168px;
  display: none;
  padding: 15px 5px;
  line-height: 20px;
  text-align: center;
  margin-top: 9px;
  left: 50%;
  margin-left: -84px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid $gainsboro-2;
  background-color: $coconut;
  color: $black-pearl;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.table .status .tooltip:before {
  content: "";
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid $gainsboro-2;
  width: 14px;
  height: 14px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background: $full-white;
  position: absolute;
  left: 50%;
  top: -5px;
  margin-left: -7px;
}

.table .status .tooltip:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 18px;
  background: $full-white;
}

.table .status.red {
  background-color: $cinnabar;
}

.table th {
  height: 38px;
  padding: 11px 20px 6px;
  line-height: 19px;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.prop-images {
  margin-bottom: 30px;

  img {
    max-width: 100%;
    height: auto;
  }
}

.flex-direction-column {
  div {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 10px;
    border-radius: 4px;
    align-self: flex-start;
  }
}

.prop-stats {
  .col-md {
    border-right: 1px solid $horizon;

    @media (max-width: 767px) {
      border: 0;
    }
  }

  .hid-hoder {
    border-radius: 4px;
    margin-bottom: 30px;

    .stats {
      padding: 25px 15px;
    }
  }
}

.box .prop-stats {
  color: $full-white;

  p {
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 24px;
    color: $full-white;
    letter-spacing: 0;
  }

  h5 {
    margin-bottom: 12px;
  }

  .hid-hoder {
    margin-bottom: 0px;

    .stats {
      padding: 15px 5px;
    }
  }
}

.primary-invest.box {
  .table {
    td {
      padding: 15px 12px 19px;
    }
  }

  h4 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  small {
    font-size: 11px;
    font-weight: bold;
    line-height: 1;
    display: block;
  }

  .table {
    margin-bottom: 30px;
  }
}

.table thead th {
  color: $full-white;
  background: linear-gradient(180deg, $dark-cerulean 0%, $endeavour 100%);
}

.table.sm.port-break {
  thead th,
  tbody td,
  tfoot td {
    font-size: 14px !important;
  }

  table th:first-child {
    padding-left: 20px;
  }

  table td:first-child {
    padding-left: 20px;
  }

  table .amount {
    width: 20%;
    padding-right: 20px;
  }
}

.table.sm table .portfolio {
  width: 28%;

  a {
    cursor: pointer;
  }
}

.table.sm table .self-select-property {
  width: 40%;
}

.table.sm table .self-select-tranche {
  width: 20%;
  text-align: center;
  // white-space: nowrap;
}

.table.sm table .loans {
  width: 15%;
}

.table.sm table .rate {
  width: 30%;
}

.table.sm table .amount {
  width: 20%;
  padding-right: 30px;
}

.table.sm table tr:first-child td {
  padding-top: 15px;
}

.table.sm table tr td {
  padding: 11px 0;
  background: none;
}

.table.sm table td:first-child {
  padding-left: 30px;
}

.table.sm table tfoot td {
  color: $gull-grey;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  padding-bottom: 18px;
}

.table.sm table th {
  padding: 0;
  color: $full-white;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  padding-top: 4px;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.table.sm table th:first-child {
  padding-left: 30px;
}

.table.h22 tbody td {
  padding-top: 35px;
  padding-bottom: 35px;
  white-space: nowrap;
}

.table.h22 table tfoot th {
  background-color: $gainsboro-3;
  color: $black-pearl;
  font-size: 18px;
  font-weight: 500;
  padding: 25px 0 25px 20px;
  letter-spacing: 0;
  line-height: 24px;
  text-transform: uppercase;
}

.table.h22 table tfoot th:first-child {
  padding-left: 50px;
}

.table.h22 table tfoot th.funded {
  padding-right: 20px;
}

.table.h22 table tfoot th.loan {
  text-align: right;
  padding-right: 2.8%;
}

.table.h22 table tfoot .progress-bar.h6 {
  background: $snow-2;
}

.help-link {
  padding: 34px 30px 30px;
  text-align: center;
}

.help-link a {
  display: block;
}

.help-link a:hover h4 {
  color: $picton-blue;
}

.help-link p,
.help-link a p {
  color: $gull-grey;
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.help-link h4 {
  color: $black-pearl;
  font-size: 18px;
  transition: all 0.3s ease;
  padding: 32px 0 11px;
  font-weight: 500;
  line-height: 18px;
}

.border-v > div {
  border-left: 1px solid $whisper;
}

.border-v > div:first-child {
  border: 0;
}

.pos-hidden {
  position: absolute;
  left: -99999px;
}

.depositForm {
  margin-bottom: 40px;
}

.dob-container {
  display: flex;

  .dob {
    width: 25%;
    margin-top: 0 !important;
    margin-right: 10px;

    &.dob-year {
      width: 30%;
    }
  }
}

.slick-slider.row {
  width: 100%;
}

.row.d-md-flex {
  width: 100%;
}

@media (max-width: 992px) {
  .header h1 {
    display: none;
  }
}

.slick-prev,
.slick-next {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMycHgiIGhlaWdodD0iMzJweCIgdmlld0JveD0iMCAwIDQ1MS44NDYgNDUxLjg0NyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDUxLjg0NiA0NTEuODQ3OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTM0NS40NDEsMjQ4LjI5MkwxNTEuMTU0LDQ0Mi41NzNjLTEyLjM1OSwxMi4zNjUtMzIuMzk3LDEyLjM2NS00NC43NSwwYy0xMi4zNTQtMTIuMzU0LTEyLjM1NC0zMi4zOTEsMC00NC43NDQgICBMMjc4LjMxOCwyMjUuOTJMMTA2LjQwOSw1NC4wMTdjLTEyLjM1NC0xMi4zNTktMTIuMzU0LTMyLjM5NCwwLTQ0Ljc0OGMxMi4zNTQtMTIuMzU5LDMyLjM5MS0xMi4zNTksNDQuNzUsMGwxOTQuMjg3LDE5NC4yODQgICBjNi4xNzcsNi4xOCw5LjI2MiwxNC4yNzEsOS4yNjIsMjIuMzY2QzM1NC43MDgsMjM0LjAxOCwzNTEuNjE3LDI0Mi4xMTUsMzQ1LjQ0MSwyNDguMjkyeiIgZmlsbD0iI0ZGRkZGRiIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
  background-size: contain;
  right: 15px;
  z-index: 9999;
  background-repeat: no-repeat;

  &:before {
    display: none;
  }

  &:focus,
  &:hover {
    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMycHgiIGhlaWdodD0iMzJweCIgdmlld0JveD0iMCAwIDQ1MS44NDYgNDUxLjg0NyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDUxLjg0NiA0NTEuODQ3OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTM0NS40NDEsMjQ4LjI5MkwxNTEuMTU0LDQ0Mi41NzNjLTEyLjM1OSwxMi4zNjUtMzIuMzk3LDEyLjM2NS00NC43NSwwYy0xMi4zNTQtMTIuMzU0LTEyLjM1NC0zMi4zOTEsMC00NC43NDQgICBMMjc4LjMxOCwyMjUuOTJMMTA2LjQwOSw1NC4wMTdjLTEyLjM1NC0xMi4zNTktMTIuMzU0LTMyLjM5NCwwLTQ0Ljc0OGMxMi4zNTQtMTIuMzU5LDMyLjM5MS0xMi4zNTksNDQuNzUsMGwxOTQuMjg3LDE5NC4yODQgICBjNi4xNzcsNi4xOCw5LjI2MiwxNC4yNzEsOS4yNjIsMjIuMzY2QzM1NC43MDgsMjM0LjAxOCwzNTEuNjE3LDI0Mi4xMTUsMzQ1LjQ0MSwyNDguMjkyeiIgZmlsbD0iI0ZGRkZGRiIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.slick-prev {
  transform: translate(0, -50%) rotate(180deg);
  left: 15px;
  right: auto;
}

.hid-hoder {
  position: relative;
  margin-bottom: 7px;
  background-image: linear-gradient(180deg, $dark-cerulean 0%, $endeavour 100%);
  box-shadow: 0 1px 2px 0 $full-black-006;
  color: $full-white;

  .wrapper {
    max-height: 0;
    transition: 0.5s max-height;
  }

  .stats {
    padding: 40px 15px;
  }

  h5 {
    font-size: 14px;
    color: $heather;
    line-height: 1;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 17px;
    white-space: nowrap;
  }

  p {
    font-size: 25px;
    color: $full-white;
    letter-spacing: 0;
  }

  &.active {
    .wrapper {
      max-height: 150px;

      @media (max-width: 992px) {
        max-height: 99999px;

        .col-md {
          margin-bottom: 30px;
        }
      }
    }

    .vis-down {
      transform: rotate(180deg);
      border-radius: 4px 4px 0 0;
      box-shadow: 0 -1px 2px $full-black-006;
    }

    .stats {
      display: block;
    }
  }
}

.d-md-none {
  @media (min-width: 993px) {
    display: none;
  }
}

.d-none {
  display: none;
}

.d-md-flex {
  @media (min-width: 993px) {
    display: flex;
  }
}

.slick-track {
  width: 100%;
}

.vis-down {
  position: absolute;
  width: 50px;
  margin: auto;
  cursor: pointer;
  z-index: 9;
  margin-bottom: -16px;
  display: block;
  height: 16px;
  box-shadow: 0 1px 2px $full-black-006;
  border-radius: 0 0 4px 4px;
  background: $full-white url("#{$imagesPath}/ico_down_s.svg") no-repeat 50% 50%;
  bottom: 0;
  left: 0;
  right: 0;
}

.dashboard {
  min-height: 500px;
  background-image: linear-gradient(180deg, $dark-cerulean 0%, $endeavour 100%);
  padding: 60px 0 20px;
  position: relative;
}

.dashboard .container {
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  width: auto;
  margin: 0 4.4%;
}

.dashboard .canvas-h {
  padding-top: 50px;
  position: relative;
  overflow: hidden;
}

.dashboard canvas {
  width: calc(100% + 36px) !important;
  margin-left: -22px;
  height: 438px !important;
  display: block;
}

.dashboard .stat-over {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

.dashboard .btns {
  float: right;
  padding: 35px 0 0;
  font-size: 0;
  margin-right: -15px;
}

.dashboard .btns .btn {
  margin-left: 17px;
}

.dashboard .stat {
  float: left;
  padding-right: 4.8%;
}

.dashboard .stat h6 {
  color: $picton-blue;
  font-size: 14px;
  padding-bottom: 21px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.dashboard .stat .digits {
  color: $full-white;
  font-family: Roboto, sans-serif;
  font-size: 48px;
  font-weight: 300;
}

#chartjs-tooltip {
  transition: opacity 0.3s ease;
  border-radius: 4px;
  background-color: $snow;
  z-index: 100;
  min-height: 167px !important;
}

#chartjs-tooltip table {
  width: 100%;
}

#chartjs-tooltip th {
  color: $gull-grey;
  padding: 3px 0 2px;
  font-size: 14px;
  border-bottom: 1px solid $light-grey-2;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
}

#chartjs-tooltip td {
  color: $gull-grey;
  font-size: 16px;
  padding: 0 17px;
  font-weight: 400;
  line-height: 30px;
}

#chartjs-tooltip td strong {
  float: right;
  font-weight: normal;
  color: $black-pearl;
  width: 45%;
}

#chartjs-tooltip tr:first-child td {
  padding-top: 7px;
}

.btn.no-wrap {
  white-space: nowrap;
}

.modal-overlay .col-md-6 .btn {
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 15px;
  }
}

.panel.mt-30,
.btn.mt-30,
.mt-30 {
  margin-top: 30px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.btn {
  transition: all 0.3s ease;
  text-align: center;
  min-width: 100px;
}

.btn.btn-block {
  display: block;
  width: 100%;
}

.btn.btn-primary {
  padding: 0 20px;
  height: 40px;
  border: 0;
  display: inline-block;
  border-radius: 4px;
  background-color: $persian-green;
  color: $full-white;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;

  &[disabled] {
    pointer-events: none;
    background-color: $light-grey;
  }
}

.btn.btn-primary.disabled {
  pointer-events: none;
  background-color: $light-grey;
}

.btn.btn-primary:hover {
  background-color: $black-pearl;
}

.btn.btn-caution {
  padding: 0 20px;
  height: 40px;
  border: 0;
  display: inline-block;
  border-radius: 4px;
  background-color: $cinnabar;
  color: $full-white;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;

  &[disabled] {
    pointer-events: none;
    background-color: $light-grey;
  }
}

.btn.btn-caution.disabled {
  pointer-events: none;
  background-color: $light-grey;
}

.btn.btn-caution:hover {
  background-color: $black-pearl;
}

.btn.btn-secondary {
  padding: 0 20px;
  height: 40px;
  border: 0;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid $light-grey;
  background-color: $concrete;
  color: $black-pearl;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 38px;
}

.btn.btn-secondary:hover {
  background-color: $gainsboro-4;
}

.btn.btn-outline {
  padding: 0 20px;
  height: 40px;
  border: 0;
  display: inline-block;
  border-radius: 4px;
  background: none;
  border: 1px solid $gainsboro-4;
  color: $full-white;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 38px;
}

.btn.btn-outline:hover {
  background-color: $endeavour-2;
}

.checkbox label {
  display: block;
  padding: 0 0 14px;
  position: relative;
  cursor: pointer;

  &.terms-checkbox {
    padding-bottom: 0;

    span {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;

      &:before,
      &:after {
        top: 4px !important;
      }

      a {
        color: $dodger-blue;
      }
    }

    &.small {
      span {
        color: $raven;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
  }
}

.checkbox label input {
  position: absolute;
  left: -999em;
}

.checkbox label span {
  color: $black-pearl;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: block;
  letter-spacing: 0;
  text-transform: none;
  padding-left: 35px;
  margin-left: 1px;
  position: relative;
}

.checkbox label span:before {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 2px solid $whisper;
}

.checkbox label input:checked + span:after {
  content: "";
  width: 12px;
  position: absolute;
  top: 1px;
  left: 0;
  margin: 4px;
  height: 12px;
  border-radius: 2px;
  background-color: $picton-blue;
}

.radios-inline {
  padding: 5px 0 16px;
  margin-left: -2px;

  &.alt {
    padding-bottom: 0;

    .radio {
      padding-bottom: 0;

      label {
        padding-bottom: 0;
      }
    }
  }
}

.radios-inline .radio {
  display: inline-block;
  margin-right: 40px;
}

.radio label {
  display: block;
  padding: 0 0 14px;
  position: relative;
  cursor: pointer;
}

.radio label input {
  position: absolute;
  left: -999em;
}

.radio label > span {
  color: $black-pearl;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  padding-left: 33px;
  color: $gull-grey;
  margin-left: 1px;
  position: relative;
}

.radio label > span:before {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 2px solid $whisper;
}

.radio label > input:checked + span {
  color: $black-pearl;
}

.radio label > input:checked + span:after {
  content: "";
  width: 14px;
  position: absolute;
  top: -1px;
  left: 0;
  margin: 4px;
  height: 14px;
  border-radius: 50%;
  background-color: $picton-blue;
}

.radio label > input:checked[disabled] + span:after {
  background-color: $raven;
}

.radio.sm label > span {
  padding-left: 30px;
  font-size: 16px;
  line-height: 22px;
  color: $raven;
  display: block;
}

.radio.sm label > span:before {
  width: 22px;
  height: 22px;
}

.radio.sm label > input:checked + span {
  color: $elephant;
}

.radio.sm label > input:checked + span:after {
  margin: 4px;
  width: 14px;
  height: 14px;
}

.extra {
  margin: 30px 0 0;
  display: none;
}

.extra .config {
  float: right;
  background: url("#{$imagesPath}/ico_settings.svg") no-repeat 0 50%;
  padding-left: 23px;
  color: $black-pearl;
  font-size: 14px;
  position: relative;
  z-index: 9;
  margin-top: 40px;
  font-weight: 500;
  margin-right: -5px;
  background-size: 17px 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.extra .config:hover {
  color: $picton-blue;
}

.extra .radio.sm {
  margin-top: 27px;
  margin-left: -2px;
  margin-bottom: -20px;
}

.extra .radio.sm label > span {
  color: $black-pearl;
  padding-left: 41px;
  font-size: 18px;
  display: block;
  font-weight: 500;
  line-height: 20px;
}

.extra .radio.sm label > span:after,
.extra .radio.sm label > span:before {
  content: "";
  top: 11px;
}

.extra .radio.sm span > span.desc {
  display: block;
  padding-top: 3px;
  color: $raven;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.mw-140 {
  min-width: 140px;
}

.radio-tabs {
  position: absolute;
  left: -999em;
  top: 0;
}

.radio-tabs.active {
  left: 0;
  position: static;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.5;
}

::-moz-placeholder {
  color: inherit;
  opacity: 0.5;
}

.pt-11 {
  padding-top: 11px;
}

label + .form-control {
  margin-top: 4px;
  margin-bottom: 4px;
}

.btn {
  cursor: pointer;
}

textarea.form-control,
input.form-control {
  width: 100%;
  height: 46px;
  box-shadow: 0 1px 2px $full-black-006;
  border-radius: 4px;
  border: 1px solid $light-grey;
  padding: 0 15px;
  color: $black-pearl;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  background: $snow;

  + .form-control {
    margin-top: 10px;
  }
}

.sortcode {
  display: flex;
  justify-content: space-between;

  .sort {
    display: block;
    width: 28%;
    margin-right: 8%;
    position: relative;

    .dash {
      position: absolute;
      right: -20px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

p.error {
  color: red !important;
}

textarea.form-control[value],
input.form-control[value] {
  border: 1px solid $gull-grey;
}

textarea.form-control:focus,
input.form-control:focus {
  border: 1px solid $gull-grey;
}

textarea.datepicker,
input.datepicker {
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-image: url("#{$imagesPath}/ico_calendar.svg");
}

label {
  display: block;
  color: $raven;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

label.dc {
  color: $black-pearl;
}

label .grey {
  color: $grey-chateau;
}

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  background: $midnight-08;
  left: 0;
  right: 0;
  z-index: 999;
  overflow: auto;
  display: none;

  .box {
    max-height: 94vh;
    overflow: auto;
    border: 10px solid $full-white;

    a {
      color: $picton-blue;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.modal-overlay.active {
  display: block;
}

.modal-overlay .overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal-overlay .bt {
  display: table;
  height: 100%;
  width: 100%;
}

.modal-overlay .bt .bc {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  padding: 15px;
}

.modal-overlay .box {
  width: 520px;
  z-index: 9;
  max-width: calc(100vw - 30px);
  position: relative;
  margin: auto;
  box-shadow: 0 1px 2px $full-black-006;
  border-radius: 4px;
  background-color: $full-white;
  padding: 30px;

  &.primary-invest {
    width: 770px;
  }
}

.create-classic {
  .checkbox label.terms-checkbox span {
    font-weight: 400;
    font-size: 18px;
  }

  .checkbox {
    margin-bottom: 15px;
  }
}

.modal-overlay h3 {
  color: $black-pearl;
  font-family: Roboto, sans-serif;
  font-size: 26px;
  font-weight: 400;
  // line-height: 18px;
  padding-bottom: 29px;

  @media (max-width: 767px) {
    line-height: 1.3;
  }
}

.modal-overlay .data-set {
  padding: 20px 0;
}

.modal-overlay p {
  color: $black-pearl;
  font-size: 16px;
  padding-bottom: 24px;
  font-weight: 400;
  line-height: 24px;
}

.modal-overlay hr {
  display: block;
  margin: 6px 0 2px;
  border: 0;
  height: 1px;
  background-color: $whisper;
}

.modal-overlay .btn {
  min-width: 170px;
}

.modal-overlay .stat-list {
  padding: 5px 0 45px;
}

.modal-overlay .stat-list h6 {
  color: $raven;
  padding-bottom: 11px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.modal-overlay .stat-list strong {
  display: block;
  color: $black-pearl;
  font-family: Roboto, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 18px;
}

select.form-control.select-2 {
  width: 100%;
  height: 46px;
  box-shadow: 0 1px 2px $full-black-006;
  border-radius: 4px;
  border: 1px solid $gull-grey;
  background-color: $snow;
  text-transform: none;
  font-size: 16px;
  letter-spacing: 0;
  color: $black-pearl;
}

.funded h6 {
  font-size: 10px;
  margin-top: -10px;
  font-weight: bold;
}

.tabs-row {
  margin: -25px 0 80px;
  border-bottom: 1px solid $gainsboro;

  + .filter-row {
    margin-top: -60px;

    @media (max-width: 992px) {
      margin-top: 0;
    }
  }
}

.loans-section-header {
  .tabs-row {
    margin: -25px 0 40px;
  }
}

.banking-header .tabs-row {
  margin-bottom: 0;
}

.tabs-row.mb-57 {
  margin-bottom: 57px;
}

.tabs-row:after {
  content: "";
  clear: both;
  display: block;
}

.tabs-row .right {
  float: right;
}

.tabs-row .right li {
  padding: 0 0 0 38px;
}

.tabs-row p {
  float: left;
  color: $grey;
  color: $black-pearl;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  line-height: 26px;
}

.tabs-row li {
  float: left;
  padding-right: 30px;
}

p.success {
  color: $persian-green !important;
  font-weight: bold;
}

.tabs-row a,
.tabs-row button {
  display: block;
  padding: 5px 0 14px;
  opacity: 0.5;
  color: $raven;
  font-size: 16px;
  margin-bottom: -1px;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  border-bottom: 2px solid transparent;
  line-height: 18px;
}

.tabs-row .back {
  color: $grey;
  opacity: 1;
  font-weight: normal;
  color: $black-pearl;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
}

.tabs-row .back:hover {
  color: $picton-blue;
}

.tabs-row a.current,
.tabs-row a:hover,
.tabs-row button.current,
.tabs-row button:hover {
  opacity: 1;
  border-bottom: 2px solid $black-pearl;
}

.hotels-list {
  margin-bottom: -34px;
}

.hotel-item {
  margin-bottom: 52px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: $full-white;
  overflow: hidden;
  transition: all 0.2s ease-in;
}

.hotel-item-custom:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 30px;
  // border: 1px solid $persian-green;
  // border-radius: 8px;
  cursor: pointer;
}

.hotel-item img {
  width: 100%;
  height: auto;
}

.hotel-item .text {
  padding: 25px 0 0;
  margin: 0 30px;
}

.hotel-item ul {
  border-top: 1px solid $whisper;
  color: $raven;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 26px 0 5px;
}

.hotel-item ul li {
  padding-bottom: 16px;
}

.hotel-item ul .right {
  float: right;
  color: $black-pearl;
  text-transform: none;
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 400;
  line-height: 24px;
}

.hotel-item .bottom {
  border-radius: 0 0 0 4px;
  background-color: $persian-green;
  padding: 16px;
  text-align: center;
  padding-bottom: 20px;
}

.hotel-item .bottom h6 {
  color: $gainsboro-5;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 2px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.hotel-item .bottom strong {
  display: block;
  color: $full-white;
  font-family: Roboto, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 18px;
}

.hotel-item h2 {
  text-align: center;
  color: $black-pearl;
  font-family: Roboto, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 28px;
  padding-bottom: 8px;
}

.hotel-item h4 {
  text-align: center;
  color: $raven;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.clickable {
  cursor: pointer;
}

.pca-box ul {
  max-height: 250px;
  border: 1px solid rgba(lightgrey, 0.8);
  overflow-y: scroll;

  li {
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;

    strong {
      display: inline-block;
      margin-right: 10px;
    }

    &:hover {
      background: lightgrey;
    }
  }
}

.panel p.error-state {
  color: $cinnabar;
  font-size: 18px;
  font-weight: 500;
  padding: 2px 0 0;
  margin-bottom: -3px;
  line-height: 26px;
}

.notes-modal {
  h3 {
    padding-bottom: 10px;
  }

  .grey {
    color: grey;
  }

  p.grey {
    padding-bottom: 15px;
  }

  h6 {
    margin-top: 15px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
}

.error span {
  display: block;
}

.d-flex {
  display: flex;
}

.login-link {
  margin-top: 20px;

  a {
    color: $persian-green;
    font-weight: 400;
  }

  + .login-link {
    margin-top: 0;
  }
}

.secondary-invest.box {
  form {
    .row {
      margin-bottom: 20px;
    }

    select.form-control {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: normal;
      height: 46px;
    }

    label {
      margin-top: 10px;
    }
  }

  input + p {
    margin-top: 40px;
  }

  input + h4 {
    margin-top: 30px;
  }

  h4 {
    font-size: 22px;
    font-weight: 500;
  }
}

.transfer-panel {
  table {
    margin-top: 30px;

    span {
      display: block;
      margin-top: 7px;
    }

    td {
      padding: 0 20px 20px 0;
      font-weight: bold;
    }
  }
}

label {
  &.d-flex {
    display: flex;

    > span {
      padding-top: 11px;
      padding-right: 15px;
    }

    .data-set {
      flex-grow: 2;
    }
  }
}

select::-ms-expand {
  display: none;
}

select.form-control {
  height: 38px;
  padding: 0 15px;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  border-radius: 4px;
  border: 1px solid $gainsboro;
  background: $full-white url("#{$imagesPath}/ico_drop.svg") no-repeat right 9px
    center;
  color: $black-pearl;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  -webkit-appearance: none;
  appearance: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
}

.filter-row {
  text-align: right;
  color: $black-pearl;
  font-weight: 500;
  line-height: 18px;
  font-size: 0;
  padding: 10px 0 30px;
  overflow: hidden;
}

.filter-row .form-control {
  float: left;
  width: 230px;
}

.filter-row ul,
.filter-row .label,
.filter-row li {
  display: inline-block;
}

.filter-row .label {
  font-size: 18px;
}

.filter-row li {
  padding-left: 14px;
  padding-bottom: 10px;
}

.filter-row button {
  height: 38px;
  line-height: 36px;
  padding: 0 12px;
  display: inline-block;
  color: $black-pearl;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 4px;
  border: 1px solid $gainsboro;
  background-color: $full-white;
}

.filter-row button.current,
.filter-row button:hover {
  background-color: $picton-blue;
  text-decoration: none;
  color: $full-white;
  border-color: $picton-blue;
}

.panel + h2,
h2.martop {
  margin-top: 47px;
}

.panel .panel-head {
  color: $black-pearl;
  font-size: 18px;
  font-weight: 500;
  padding: 4px 0;
  line-height: 18px;
}

.panel .panel-head:first-child:last-child {
  padding-bottom: 0;
  margin-bottom: -5px;
}

.panel .panel-head + p {
  padding: 0;
  margin: 0 0 -5px;
}

.toggle-btn {
  display: inline-block;
  width: 68px;
  position: relative;
  vertical-align: middle;
  height: 34px;
  cursor: pointer;
}

.toggle-btn input {
  position: absolute;
  left: -999em;
}

.toggle-btn span {
  position: absolute;
  top: 0;
  padding: 0 9px;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50px;
  border: 1px solid $gull-grey;
  background-color: $whisper-2;
  line-height: 32px;
  color: $raven;
  font-size: 12px;
  text-align: left;
  transition: all 0.3s ease;
  font-weight: 500;
  text-transform: uppercase;
}

.toggle-btn span:before {
  content: "";
  width: 28px;
  height: 28px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.38);
  background-color: $concrete;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 2px;
  border-radius: 50%;
}

.toggle-btn span.on {
  opacity: 0;
}

.toggle-btn span.on:before {
  left: auto;
}

.toggle-btn span.off {
  opacity: 1;
  padding-left: 33px;
}

.toggle-btn input:checked ~ span {
  border-color: $cerulean;
  background: $cerulean;
  color: $full-white;
}

.toggle-btn input:checked ~ span.on {
  opacity: 1;
}

.toggle-btn input:checked ~ span.off {
  opacity: 0;
}

.panel.mb--10 {
  margin-bottom: -10px;
}

.align-center {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.align-center .toggle-btn {
  margin-bottom: -6px;
}

.align-center .btn {
  margin-bottom: -4px;
}

.panel .arrow-link {
  display: block;
  color: $gull-grey;
  margin: 2px 0 -6px;
  font-size: 16px;
  border: 0;
  font-weight: 400;
  background: url("#{$imagesPath}/ico_next.svg") no-repeat right 2px center;
  padding-right: 40px;
  line-height: 24px;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.panel button.arrow-link {
  min-height: 20px;

  .panel-head {
    margin-bottom: 0 !important;
  }
}

.panel .arrow-link p {
  padding: 0;
  margin: 0;
}

.progress-bar {
  height: 10px;
  margin-top: 7px;
  border-radius: 5px;
  background-color: $whisper-3;
}

.progress-bar + h6 {
  margin-top: 0;
  margin-bottom: -10px;
}

.progress-bar.h6 {
  height: 6px;
  border-radius: 3px;
  background-color: $gainsboro;
  margin-top: 0;

  max-width: 120px;
}

.progress-bar.h6 .inside {
  border-radius: 3px;
}

.progress-bar .inside {
  height: 100%;
  border-radius: 5px;
  background-color: $persian-green;
}

.password-hint {
  font-size: 12px;
  line-height: 1.2;
}

.primary-invest .error {
  display: block;
  line-height: 1.4;
  margin-bottom: 30px;
  margin-top: -15px;

  @media (max-width: 767px) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.date-set {
  position: relative;
}

.date-set .act {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.ui-datepicker {
  position: absolute;
  box-shadow: 0 1px 2px $full-black-006;
  border-radius: 4px;
  width: 260px;
  max-width: 100wv;
  margin-top: -1px;
  border: 1px solid $gull-grey;
  text-align: center;
  display: none;
  background-color: $snow;
  padding: 10px;
}

.ui-datepicker .ui-datepicker-next {
  float: right;
}

.ui-datepicker .ui-datepicker-title {
  padding-bottom: 10px;
}

.ui-datepicker th {
  font-weight: 500;
}

.ui-datepicker a {
  cursor: pointer;
}

.ui-datepicker .ui-datepicker-prev {
  float: left;
}

.ui-datepicker table {
  width: 100%;
}

.ui-datepicker td {
  padding: 5px;
}

.panel {
  // box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.12);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 4px;
  background: $full-white;
  padding: 25px 30px 30px;
  margin-bottom: 18px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  &.loan-list,
  &.no-overflow {
    overflow: visible;
  }
}

.panel hr {
  display: block;
  margin: 0 -30px 24px;
  border: 0;
  height: 1px;
  background-color: $whisper;
}

.panel hr + .data-set {
  padding-top: 4px;
}

.panel hr.mt-26 {
  margin-top: 26px;
}

.panel hr.mlr {
  margin-left: 0;
  margin-right: 0;
}

.panel p {
  color: $black-pearl;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 17px;
}

@media (max-width: 992px) {
  .panel .table p.pb {
    font-size: 13px;
  }
}

.panel p.intro {
  padding: 0 2% 0 0;
  margin: 2px 0 24px;
}

.panel.mb-30 {
  margin-bottom: 30px;
}

.panel.p-20 {
  padding: 20px;
}

.mb-65 {
  margin-bottom: 65px;
}

.panel.mb-50 {
  margin-bottom: 53px;
}

.panel.mb-40 {
  margin-bottom: 31px;
}

.data-set {
  padding-bottom: 24px;
}

.data-set.pb-0 {
  padding-bottom: 0;
}

.info-text {
  color: $black-pearl;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.info-text.big {
  font-family: Roboto, sans-serif;
  font-size: 26px;
  line-height: normal;
  padding: 2px 0 6px;
  font-weight: 400;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.body {
  overflow: hidden;
}

.body.body-dashboard {
  overflow: visible;
}

.footer {
  background-color: $black-pearl;
  padding: 75px 0;
  overflow: hidden;
}

.footer p {
  color: $raven;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 20px;
}

.footer div div {
  color: $raven;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  // margin-bottom: 20px;
}
.footer div div p {
  margin-top: 10px;
}
.footer {
  .container {
    div:nth-child(2) {
      a {
        // margin-top: 10px;
        text-decoration: underline;
      }
    }
  }
}

.footer h3 {
  color: $blue-bayoux;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  padding-bottom: 10px;
  letter-spacing: 1px;
}

.footer ul {
  padding: 10px 0 0;
}

.footer a:hover {
  color: $raven;
}

.footer li {
  color: $whisper-4;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  padding-bottom: 20px;
}

.footer .logo {
  margin: 6px 0;
  display: inline-block;
  vertical-align: top;
}

.footer .widget {
  padding-bottom: 52px;
}

.footer .widget p {
  color: $whisper-4;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin-top: 5px;
}

#chartjs-tooltip {
  position: absolute;
  width: 205px;
  margin: -155px 0 0 -85px;
  height: 137px;
  border-radius: 4px;
  background-color: $snow;
}

.entry p + p {
  padding-top: 5px;
}

.entry p + ul {
  padding-top: 7px;
}

.entry ul + p {
  padding-top: 25px;
}

.entry ul {
  margin-bottom: -24px;
}

.entry ul .left {
  float: left;
  color: $raven;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
}

.entry ul .left + strong {
  float: right;
  color: $black-pearl;
  font-size: 18px;
  padding-right: 10px;
  font-weight: 500;
  line-height: 32px;
}

.entry .values {
  padding-bottom: 27px;
}

.entry .values li {
  padding-bottom: 0;
}

.entry .values li:before {
  content: "";
  margin-top: 4px;
}

.entry li {
  padding-left: 27px;
  overflow: hidden;
  padding-bottom: 24px;
  position: relative;
}

.entry li:before {
  content: "";
  background: url("#{$imagesPath}/ico_bullet.svg") no-repeat;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 0;
  top: 6px;
}

/* Fonts
------------------------------------------------------------------------------*/

@font-face {
  font-family: "avenirnext";
  src: url("#{$fontsPath}/avenirnextmedium.eot");
  src: url("#{$fontsPath}/avenirnextmedium.eot?#iefix")
      format("embedded-opentype"),
    url("#{$fontsPath}/avenirnextmedium.woff") format("woff"),
    url("#{$fontsPath}/avenirnextmedium.ttf") format("truetype"),
    url("#{$fontsPath}/avenirnextmedium.svg#avenirnextmedium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "avenirnextmedium";
  src: url("#{$fontsPath}/avenirnextmedium.eot");
  src: url("#{$fontsPath}/avenirnextmedium.eot?#iefix")
      format("embedded-opentype"),
    url("#{$fontsPath}/avenirnextmedium.woff") format("woff"),
    url("#{$fontsPath}/avenirnextmedium.ttf") format("truetype"),
    url("#{$fontsPath}/avenirnextmedium.svg#avenirnextmedium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "avenirnext";
  src: url("#{$fontsPath}/avenirnextregular.eot");
  src: url("#{$fontsPath}/avenirnextregular.eot?#iefix")
      format("embedded-opentype"),
    url("#{$fontsPath}/avenirnextregular.woff") format("woff"),
    url("#{$fontsPath}/avenirnextregular.ttf") format("truetype"),
    url("#{$fontsPath}/avenirnextregular.svg#avenirnextregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Media
------------------------------------------------------------------------------*/

@media screen and (max-width: 1223px) {
  .table .status .tooltip {
    left: 0;
    margin-left: -15px;
  }

  .table .status .tooltip:before {
    left: 21px;
  }

  .steps.long li {
    padding: 0 18px;
  }

  .steps.long ul {
    margin: 0 -18px;
  }
}

@media screen and (max-width: 1200px) {
  #chartjs-tooltip {
    width: 175px;
    margin: -140px 0 0 -70px;
    height: 120px;
  }

  #chartjs-tooltip td {
    padding-left: 10px;
    font-size: 13px;
    padding-right: 10px;
    line-height: 25px;
  }

  .dashboard .stat .digits {
    font-size: 40px;
  }
}

@media screen and (max-width: 1023px) {
  .panel.acc-item {
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;
  }

  .steps li {
    padding: 0 18px;
  }

  .steps ul {
    margin: 0 -18px;
  }

  .steps.long li {
    padding: 0 10px;
  }

  .steps.long li h4 {
    font-size: 12px;
  }

  .steps.long ul {
    margin: 0 -10px;
  }

  .table .risk {
    padding-left: 0;
    width: auto;
  }

  .table .grate {
    width: 8%;
    padding-right: 0;
  }

  .table.h22 table tfoot th:first-child {
    padding-left: 30px;
  }

  .table .funded {
    width: 25%;
    padding-right: 20px;
  }

  .table.h22 table tfoot th.funded {
    padding-right: 20px;
  }

  .table.h22 table tfoot th {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .table.h22 table tfoot th {
    padding-left: 0;
  }

  .table .loan {
    padding-right: 15px;
    width: 20%;
  }

  .dashboard .stat .digits {
    font-size: 30px;
    line-height: 1;
  }

  .dashboard {
    height: auto;
    padding-bottom: 10px;
  }

  .dashboard .canvas-h {
    padding-top: 0;
  }

  .dashboard .btns {
    margin: 0;
  }

  .dashboard .btns {
    padding-top: 10px;
    margin-left: -5%;
  }

  .dashboard .stat {
    padding-right: 3%;
  }

  .dashboard .stat h6 {
    font-size: 13px;
    padding-bottom: 10px;
    line-height: normal;
  }

  .dashboard .container {
    margin: 0;
    padding: 0 15px;
  }

  .dashboard .stat-over {
    padding: 0 15px;
  }

  .table th,
  .table td {
    padding: 10px 5px;
    line-height: 1.2;
    font-size: 14px;
  }

  .table h4 {
    font-size: 14px;
    line-height: 1.2;
  }

  .table td p {
    font-size: 12px;
    line-height: 1.2;
  }

  .table th {
    font-size: 12px;
  }

  .table .status {
    margin-right: 10px;
    margin-left: 5px;
    min-width: 14px;
  }

  .table .amount {
    padding-right: 5px;
  }

  .footer {
    padding: 50px 0;
  }

  .footer .widget {
    padding-bottom: 40px;
  }

  .header .main-nav .user .trigger {
    padding: 0 18px;
    font-size: 13px;
  }

  .header .main-nav ul {
    padding: 0 10px 0 0;
  }

  .gutters-40,
  .gutters-52 {
    margin-left: -16px;
    margin-right: -16px;
  }

  .gutters-40 > div,
  .gutters-52 > div {
    padding-left: 16px;
    padding-right: 16px;
  }

  .header .main-nav > ul a {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .header h1 {
    margin-left: 12px;
    padding-left: 10px;
    font-size: 13px;
  }

  .footer .widget li,
  .footer .widget p {
    font-size: 14px;
    line-height: 2;
  }

  .footer .widget ul {
    padding: 7px 0;
  }

  .footer .widget li {
    padding-bottom: 14px;
    line-height: 1;
  }

  .hotel-item .bottom h6 {
    font-size: 12px;
  }

  .hotel-item h2 {
    font-size: 24px;
  }

  .hotel-item .text {
    margin: 0 15px;
  }

  .hotel-item .bottom strong {
    font-size: 24px;
  }

  .menu-trigger {
    position: absolute;
    // top: 4px;
    z-index: 9;
    display: block;
    cursor: pointer;
    margin: 15px;
    width: 40px;
    padding-top: 6px;
    right: 0;
  }
  .header .main-nav {
    position: absolute;
    padding-top: 70px;
    left: 0;
    right: 0;
    height: auto;
    box-shadow: 0 1px 2px $full-black-006;
    background: $full-white;
    z-index: 1;
    // transform: translateX(100%);
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
  .menu-trigger span {
    height: 3px;
    background: $black-pearl;
    display: block;
    margin: 0 0 6px;
  }
  .header .main-nav > ul {
    float: none;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .header .main-nav > ul li {
    float: none;
  }

  .header .main-nav > ul a {
    float: none;
    display: block;
    line-height: normal;
    font-size: 16px;
    padding: 12px 15px;
    border-left: 2px solid transparent;
    border-bottom: 0;
    height: auto;
  }

  .active-menu .header .main-nav {
    display: block;
  }

  .header .main-nav .user {
    float: none;
    height: auto;
    padding: 0;
    line-height: normal;
  }

  .header .main-nav .user .trigger {
    padding: 15px;
  }

  .header .main-nav .dropdown {
    width: auto;
    left: 0;
    position: static;
  }
}

@media screen and (max-width: 767px) {
  .panel .complete-item {
    padding-top: 20px;
  }

  .panel .complete-item img {
    max-width: 70%;
    height: auto;
  }

  .panel .complete-item h2 {
    padding-top: 25px;
    padding-bottom: 10px;
  }

  .panel .complete-item p {
    font-size: 14px;
    line-height: 2;
    padding-bottom: 28px;
  }

  .steps li.done {
    position: absolute;
    right: 50%;
    margin-right: 60px;
    z-index: 9;
    padding-left: 0;
    top: 0;
    width: 50%;
    padding-top: 18px;
    background-color: $concrete;
  }

  .steps li.done * {
    opacity: 0.5;
  }

  .steps {
    position: relative;
  }

  .steps h4 {
    font-size: 12px;
  }

  .steps li.current {
    width: 65%;
    margin: auto;
    z-index: 99;
    position: relative;
  }

  .steps li.current + li {
    position: absolute;
    left: 50%;
    top: 0;
    white-space: nowrap;
    margin-left: 60px;
    z-index: 9;
    padding-top: 18px;
    width: 50%;
    background-color: $concrete;
  }

  .steps li.current + li * {
    opacity: 0.5;
  }

  .page-title {
    padding: 0 0 15px;
    font-size: 30px;
    line-height: 1.2;
  }

  .steps {
    padding: 20px 0;
  }

  .steps li {
    display: none;
  }

  .steps li.current {
    display: block;
  }

  .steps li:after,
  .steps li:before {
    content: "";
    display: none;
  }

  .panel.acc-item p {
    font-size: 14px;
    line-height: 2;
  }

  .steps li {
    padding: 0 10px;
  }

  .steps ul {
    margin: 0 -10px;
  }

  .entry ul .left,
  .entry ul .left + strong {
    font-size: 14px;
  }

  .entry ul {
    font-size: 15px;
  }

  .modal-overlay .box {
    padding: 25px 15px 15px;
  }

  .modal-overlay .stat-list {
    padding-bottom: 20px;
  }

  .modal-overlay .bt .bc {
    padding: 40px 15px;
  }

  .modal-overlay hr {
    margin-top: 0;
  }

  .table.h22 table tfoot th {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .modal-overlay .stat-list h6 {
    font-size: 12px;
    line-height: normal;
    padding-bottom: 3px;
  }

  .modal-overlay .stat-list strong {
    font-size: 18px;
    padding-bottom: 15px;
  }

  .dashboard .container {
    top: 20px;
  }

  .align-center .btn {
    min-width: 0;
  }

  .dashboard {
    overflow: hidden;
  }

  .dashboard canvas {
    width: calc(100% + 44px) !important;
    margin-left: -23px;
    height: 45vw !important;
    //margin-top: -10vw;
    display: block;
  }

  .dashboard .stat-over {
    position: static;
    padding: 0;
  }

  .dashboard .btns {
    margin: 0;
    float: left;
  }

  .dashboard .btns .btn {
    margin: 0 10px 0 0;
    padding: 0 15px;
  }

  #chartjs-tooltip {
    margin-top: -120px;
  }

  #chartjs-tooltip.to-left {
    margin-left: -125px;
  }

  #chartjs-tooltip.to-right {
    margin-left: -10px;
  }

  .dashboard .canvas-h {
    overflow: visible;
    float: left;
    width: 100%;
  }

  .dashboard .container {
    position: static;
    padding: 15px;
  }

  .dashboard .stat {
    width: 50%;
    padding: 0 0 15px;
  }

  .panel .panel-head + p {
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 0;
    padding-bottom: 6px;
  }

  .panel .panel-head {
    font-size: 16px;
    padding-top: 2px;
  }

  .filter-row .form-control {
    display: block;
    margin: -10px 0 20px;
    width: 100%;
    float: none;
  }

  .hotel-item .bottom h6 {
    font-size: 10px;
  }

  .hotel-item .text {
    padding-top: 15px;
  }

  .hotel-item h2 {
    font-size: 20px;
  }

  .hotel-item h4 {
    font-size: 12px;
    padding-bottom: 10px;
  }

  .hotel-item ul {
    padding-top: 12px;
  }

  .hotel-item ul li {
    font-size: 12px;
    padding-bottom: 8px;
  }

  .hotel-item ul .right {
    font-size: 14px;
  }

  .hotel-item .bottom {
    padding-top: 12px;
  }

  // .header .main-nav .dropdown {
  //   width: auto;
  //   left: 0;
  //   position: static;
  // }

  // .menu-trigger {
  //   position: absolute;
  //   top: 4px;
  //   z-index: 9;
  //   display: block;
  //   cursor: pointer;
  //   margin: 15px;
  //   width: 40px;
  //   padding-top: 6px;
  //   right: 0;
  // }

  // .menu-trigger span {
  //   height: 3px;
  //   background: $black-pearl;
  //   display: block;
  //   margin: 0 0 6px;
  // }

  .active-menu .menu-trigger span:nth-child(2) {
    opacity: 0;
  }

  .active-menu .menu-trigger span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin: 9px 0 -12px;
  }

  .active-menu .menu-trigger span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .footer {
    padding: 20px 0;
  }

  .footer p {
    font-size: 12px;
    line-height: 1.5;
  }

  .footer .logo {
    margin-bottom: 20px;
  }

  .footer .widget {
    padding-bottom: 20px;
  }

  .panel > .data-set:last-child {
    padding-bottom: 0;
  }

  .data-set {
    padding-bottom: 15px;
  }

  .panel {
    padding: 15px;
    margin-bottom: 30px;
    height: auto !important;
  }

  .panel hr {
    margin-left: -15px;
    margin-right: -15px;
  }

  label + .form-control {
    margin-bottom: 0;
  }

  .radios-inline {
    padding-bottom: 4px;
  }

  .body {
    padding: 30px 0;
  }

  // .header .main-nav .user {
  //   float: none;
  //   height: auto;
  //   padding: 0;
  //   line-height: normal;
  // }

  // .header .main-nav .user .trigger {
  //   padding: 15px;
  // }

  // .header .main-nav {
  //   position: absolute;
  //   top: 70px;
  //   left: 0;
  //   right: 0;
  //   height: auto;
  //   box-shadow: 0 1px 2px $full-black-006;
  //   background: $full-white;
  //   z-index: 999;
  //   display: none;
  // }

  // .header .main-nav > ul {
  //   float: none;
  // }

  // .header .main-nav > ul li {
  //   float: none;
  // }

  // .header .main-nav > ul a {
  //   float: none;
  //   display: block;
  //   line-height: normal;
  //   font-size: 16px;
  //   padding: 12px 15px;
  //   border-left: 2px solid transparent;
  //   border-bottom: 0;
  //   height: auto;
  // }

  // .active-menu .header .main-nav {
  //   display: block;
  // }

  .tabs-row {
    margin-top: -15px;
  }

  .tabs-row a {
    font-size: 14px;
  }

  .hotels-list {
    margin-bottom: -15px;
  }

  .tabs-row li {
    padding-right: 20px;
  }

  .hotel-item {
    margin-bottom: 20px;
  }

  .table {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table table {
    min-width: 767px;
  }

  .sm.table table {
    min-width: 450px;
  }

  .table.sm table td:first-child,
  .table.sm table th:first-child {
    padding-left: 15px;
  }

  .table.sm table .amount {
    padding-right: 15px;
  }

  .table.sm table tfoot td,
  .table.sm table td {
    font-size: 12px;
    padding-top: 2px;
    padding-bottom: 10px;
  }

  .table.sm table tbody td {
    padding-bottom: 6px;
  }

  .table.sm table tr:first-child td {
    padding-top: 10px;
  }

  .table.sm table th {
    padding: 4px 0 2px;
    vertical-align: middle;
    padding-right: 3px;
    font-size: 11px;
  }

  .table.sm table .rate {
    width: 21%;
  }

  .panel .panel-head:first-child:last-child {
    margin-bottom: 0;
  }

  .mb-65 {
    margin-bottom: 20px;
  }

  .extra {
    padding-bottom: 20px;
  }

  .data-set.pb-0 {
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 567px) {
  .extra .config {
    margin: 25px 0 0;
  }

  .extra .radio.sm label > span {
    padding-left: 30px;
    padding-top: 2px;
  }

  .extra .radio.sm span > span.desc {
    font-size: 12.5px;
  }

  label {
    letter-spacing: 0;
  }

  label .grey {
    font-size: 12px;
  }

  .btns-b div:last-child > .btn {
    margin-top: 10px;
  }

  .tabs-row p {
    float: none;
    padding-bottom: 7px;
  }

  .dashboard canvas {
    margin-top: -5vw;
  }

  .border-v > div {
    border: 0;
  }

  .border-v > div:before {
    content: "";
    border-top: 1px solid $whisper;
    display: block;
  }

  .border-v > div:first-child {
    border: 0;
  }

  .border-v > div:first-child:before {
    display: none;
  }

  .filter-row .form-control {
    margin: 0 0 5px;
  }

  .header h1 {
    display: none;
  }

  .filter-row ul {
    margin-right: -5px;
  }

  .tabs-row {
    margin-bottom: 0;
    margin-top: -15px;
    border: 0;
  }

  .tabs-row .right,
  .tabs-row ul {
    display: flex;
    border-bottom: 1px solid $gainsboro;
    float: none;
    margin-bottom: 15px;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .tabs-row .right li {
    padding: 0;
    margin-left: auto;
  }

  .tabs-row li {
    padding: 0 25px 0 0;
    width: auto;
  }

  .tabs-row li a {
    font-size: 13px;
    padding-bottom: 12px;
  }

  .filter-row .label {
    font-size: 14px;
    display: block;
    float: left;
    padding: 5px 0;
    padding-right: 8px;
    text-align: left;
    padding-bottom: 10px;
  }

  .filter-row {
    padding: 2px 0 10px;
    margin-top: 15px;
  }

  .filter-row ul {
    text-align: left;
  }

  .filter-row li {
    padding: 0 5px 5px 0;
  }

  .filter-row button {
    font-size: 11px;
    height: 30px;
    line-height: 28px;
    padding: 0 7px;
  }

  .filter-row ul {
    float: left;
  }
}

@media (min-width: 992px) {
  .d-md-block {
    display: block;
  }
}

.notifications {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 400px;

  .notification {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin-top: 30px;
    position: relative;
    padding: 15px;
    border: 1px solid rgba(#000, 0.1);
    background: linear-gradient(180deg, $dark-cerulean 0%, $endeavour 100%);

    .close {
      position: absolute;
      top: 7px;
      right: 7px;
      border: 0;
      font-size: 15px;
      padding: 0 5px;
      line-height: 1;
      background: none;
      color: $full-white;
    }

    h4 {
      margin-bottom: 5px;
      font-size: 18px;
      font-weight: 500;
      color: $full-white;
    }

    p {
      color: $heather;
    }
  }
}

.accountTypeLabel {
  position: fixed;
  left: -18px;
  top: 50%;
  transform: rotate(-90deg) translateY(-50%);
  background: $full-white;
  padding: 20px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.2);
  z-index: 99999;
  text-align: center;
  width: 165px;

  @media (max-width: 1023px) {
    display: none;
  }

  &.CLASSIC {
    width: 215px;
    left: -45px;
  }
}

.passportNum {
  display: flex;
  justify-content: space-between;

  input {
    margin-top: 0 !important;

    &[disabled] {
      color: $nobel;
    }

    &:nth-of-type(1) {
      width: 120px;
    }

    &:nth-of-type(2) {
      width: 42px;
    }

    &:nth-of-type(3) {
      width: 70px;
    }

    &:nth-of-type(4) {
      width: 90px;
    }

    &:nth-of-type(5) {
      width: 45px;
    }

    &:nth-of-type(6) {
      width: 97px;
    }

    &:nth-of-type(7) {
      width: 160px;
    }

    &:nth-of-type(8) {
      width: 50px;
    }
  }
}

.drivingLicense {
  display: flex;

  input {
    margin-top: 0 !important;
    margin-right: 6px;

    &:nth-of-type(1) {
      width: 120px;
    }

    &:nth-of-type(2) {
      width: 90px;
    }

    &:nth-of-type(3) {
      width: 80px;
    }

    &:nth-of-type(4) {
      width: 70px;
    }
  }
}

.prop-title {
  font-size: 40px;
  // margin-top: -35px;
  margin-bottom: 45px;
  color: $black-pearl;

  @media (max-width: 992px) {
    margin-top: 20px;
    font-size: 30px;
  }

  @media (max-width: 767px) {
    font-size: 28px;
    margin-bottom: 20px;
    line-height: 1.2;
  }
}

.errors {
  height: 52px;
  margin-top: -15px;

  .error {
    margin-top: 0;
  }

  @media (max-width: 767px) {
    height: auto;
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.bordered {
  border: 1px solid black;
}

.look-disabled {
  background-color: $light-grey !important;
}
