.loader {
  width: 100%;
  min-height: 577px;
  // max-height: 67vh;
  text-align: center;
  position: relative;

  .preloader-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.modal-overlay .loader {
  height: 40vh;
}
