$full-white: #fff;
$picton-blue: #619ecc;
$steel-blue: #4d8bbb;

.portfolio-breakdown-mobile .panel {
  padding: 0px;
}

.portfolio-breakdown-mobile .panel p {
  padding-bottom: 0px !important;
  padding-top: 0px !important;

  padding-block: 10px !important;
}

.breakdown-row-mobile-header {
  background: linear-gradient(180deg, #024679 0%, #1a5d8f 100%);
  color: $full-white;
  padding-block: 10px;
  padding-inline: 10px;
}

.breakdown-row-mobile-item {
  width: 100%;
  display: flex;
  border-bottom: solid 1.5px rgba(0, 0, 0, 0.1);
}

.breakdown-row-mobile-item:last-child {
  border-bottom: none;
}

.breakdown-row-mobile-item-left {
  //   color: $picton-blue !important;
  width: 60%;
  padding-inline: 10px;
  border-right: solid 1.5px rgba(0, 0, 0, 0.1);
}

.breakdown-row-mobile-item-right {
  width: 40%;
  padding-inline: 10px;
  //   text-align: center;
}

// .breakdown-row-mobile-item-left {
//   color: $picton-blue !important;
// }

// .breakdown-row-mobile-item p {
//   color: black;
// }
