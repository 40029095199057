$midnight-08: rgba(30, 42, 52, 0.8);
$full-black-006: rgba(0, 0, 0, 0.06);
$black-pearl: #1e2a32;
$pale-cornflower-blue: #b2d7f8;
$gainsboro: #d8d8d8;
$full-white: #fff;
$picton-blue: #619ecc;
$light-grey: #d0d0d0;
$persian-green: #00a19a;
$steel-blue: #4d8bbb;
$sapphire: #053b63;
$cinnabar: #eb5835;
$raven: #6d757b;

.confirm-details {
  margin-bottom: 30px;

  th {
    font-weight: bold;
    padding-right: 15px;
    padding-bottom: 5px;
  }

  td {
    padding-bottom: 5px;
  }
}

td.date {
  white-space: nowrap;
}

.panel .table p.pb {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
}

.pl-0 {
  padding-left: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.transactions .table table .sub-heading th {
  background: #4d8bbb;
  background: #bdbdbd;
  background: #f2f2f2;
  background: #fff;
  color: #4d8bbb !important;
}

.active-loans-table,
.exchange-loans-table,
.redeemed-loans-table,
.single-loan-table,
.sold-loans-table,
.waiting-to-close-loans-table {
  thead {
    th {
      text-align: left !important;
    }
  }
  tbody {
    td {
      text-align: left !important;
    }

    .amount {
      text-align: right;
    }
  }
  tfoot {
    background-color: #dedede;
    .name {
      padding-left: 3.5rem;
    }
    td {
      text-align: left !important;
    }
  }
}

.statement-date,
.statement-amount,
.statement-running {
  width: 15%;
}
.statement-description {
  width: 55%;
}

.statement-table {
  .statement-running {
    text-align: left !important;
  }
  .statement-amount {
    text-align: left !important;
  }
}

.property-details {
  .table {
    thead {
      .loan {
        text-align: center;
        padding-left: 22px;
      }
    }
    .tranch {
      width: 14%;
    }
    .risk {
      width: 16%;
    }
    .ltv {
    }
    .internet {
      width: 17%;
    }
    .grate {
      width: 17.9%;
    }
    .loan {
      width: 12%;
      @media screen and (max-width: 1023px) {
        text-align: center;
      }
    }
    .invest {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.portfolio-breakdown-mobile-wrapper {
  margin-bottom: 50px;
}

.loan-info-section-spacing {
  margin-bottom: 60px;
}

.loan-info-section-spacing.loan-info-section-spacing-valuation {
  margin-bottom: 40px;
}

.loan-info-section-spacing .panel {
  margin-bottom: 0px !important;
}

.loan-info-section-spacing .panel.panel-exclude-universal-spacing {
  margin-bottom: 20px !important;
}

.loan-info-section-spacing .panel h2 {
  margin-top: 0px;
}

.single-loan .panel.loan-list {
  margin-top: 53px;
}

.single-loan-accordian.panel {
  background-color: transparent;
  box-shadow: none;
  margin-inline: 0px !important;
  padding-inline: 0px;
  position: static;
}

.account-summary-tile-mobile-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0px;
  padding-top: 20px;
}

.valuation-summary-tile-mobile-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px;
  padding-top: 0px;
}

.account-summary-tile-mobile {
  width: 30%;
  border-radius: 5px;
  text-align: center;
  background-image: linear-gradient(180deg, #024679 0%, #1a5d8f 100%);
  padding-bottom: 15px;
  border-bottom: 2px solid #ffffff;
  margin-bottom: 20px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.12);
}

.account-summary-tile-mobile h5 {
  color: #fff;
  font-size: 20px;
  padding-top: 15px;
  padding-bottom: 10px;
}

.account-summary-tile-mobile p {
  margin-bottom: 10px;
  color: #fff;
}

.accordian-loans p {
  font-size: 15px !important;
}

.accordian-loans span {
  font-size: 15px !important;
}

.accordian-summary {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.single-loan-accordian p {
  padding-bottom: 17px;
}

.accordian-details-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.accordian-summary-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.accordian-summary-item-left {
  width: 75%;
}

.expanded .accordian-summary-item-left {
  width: 48%;
}

.accordian-summary-right {
  text-align: right;
}

.accordian-summary-item-right {
  width: 25%;
  text-align: right;
}

.expanded .accordian-summary-item-right {
  width: 48%;
}

.sold-loans-table {
  .amount {
    text-align: right !important;
  }
}
.redeemed-loans-table {
  .amount {
    text-align: center !important;
  }
}

.messaging-table {
  .table tbody tr.unread td {
    background: $full-white;
    border-bottom: 1px solid #f2f2f2;
  }

  .table tbody tr.read td {
    background: #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
  }

  tr {
    cursor: pointer;

    &:hover {
      color: $picton-blue;

      p {
        color: $picton-blue;
      }
    }
  }

  svg {
    color: $sapphire;
  }

  .co {
    white-space: nowrap;
    max-width: 600px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .message-title {
    margin-left: 15px;
  }
}

.sold-loans-table {
  .amount {
    text-align: center !important;
  }
}
.redeemed-loans-table {
  .amount {
    text-align: center !important;
  }
}

@media screen and (max-width: 1200px) {
  .active-loans-table,
  .exchange-loans-table,
  .redeemed-loans-table,
  .single-loan-table,
  .sold-loans-table,
  .waiting-to-close-loans-table {
    th,
    td {
      padding: 10px 5px;
      line-height: 1.2;
      font-size: 14px;
    }
    th {
      font-size: 12px;
    }
    .amount {
      text-align: start !important;
    }
  }

  .sold-loans-table {
    .amount {
      text-align: right !important;
    }
  }
  .redeemed-loans-table {
    .amount {
      text-align: center !important;
    }
  }
}

@media (max-width: 992px) {
  .single-loan-portfolio-container .loans-header-container {
    margin-bottom: 30px !important;
  }

  //

  .sort-icon {
    display: none;
  }

  .exchange-loans,
  .active-loans,
  .sold-loans,
  .redeemed-loans,
  .waiting-to-close-loans,
  .loan-list,
  .transaction-history-table,
  .messaging-table {
    background-color: #f2f2f2;
    box-shadow: none;
  }

  .responsiveTable tr {
    padding: 0px !important;
    margin-bottom: 20px;
    border: #f7f7f7 1px solid !important;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .responsiveTable td.pivoted {
    min-height: 70px;
    padding-top: 15px !important;
    word-break: keep-all;
  }

  .responsiveTable td.pivoted > div br {
    // display: none;
  }

  .responsiveTable td.pivoted:nth-child(odd) {
    background-color: #e0e0e0;
  }

  .responsiveTable td.pivoted:nth-child(even) {
    background-color: #fff;
  }

  .amount.pivoted {
    display: none;
  }
  .md-restable-none {
    display: none;
  }
}

@media screen and (max-width: 890px) {
  .active-loans,
  .exchange-loans,
  .redeemed-loans,
  .single-loan,
  .sold-loans,
  .waiting-to-close-loans {
    .table {
      overflow: auto;
    }
  }
}

@media screen and (max-width: 767px) {
  .transactions {
    .table {
      table {
        min-width: 280px;
      }
    }
  }

  .react-swipeable-view-container > div {
    margin-top: 50px !important;
    margin-bottom: 100px !important;
  }

  .statement-enddate {
    min-width: 110px;
  }

  .statement-startdate {
    min-width: 110px;
  }

  // .table table {
  //   min-width: 100px !important;
  // }
}

@media screen and (max-width: 573px) {
  .transactions {
    .start-date-container {
      margin-bottom: 10px;
    }
    form {
      margin: 10px 0px;
    }
    .statement-enddate {
      min-width: 0px;
    }

    .statement-startdate {
      min-width: 0px;
    }
  }

  .statement {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 16px;
  }

  .transaction-button {
    margin-top: 10px;
  }

  .transactions .d-flex.transactions-date-container {
    display: block !important;
  }
}

@media screen and (max-width: 350px) {
  .transactions {
    .table {
      table {
        td {
          max-width: 50%;
          // padding-right: 0px;
          // padding-left: 0px;
        }
      }
    }

    .panel {
      padding: 2px;
    }
  }

  .react-swipeable-view-container > div {
    margin-top: 50px !important;
    margin-bottom: 100px !important;
  }

  // .table table {
  //   min-width: 100px !important;
  // }
}

@media screen and (min-width: 768px) {
  .pl-sm-0 {
    padding-left: 0px;
  }
  .pr-sm-0 {
    padding-right: 0px;
  }
  .pl-sm-15px {
    padding-left: 15px;
  }

  .pr-sm-15px {
    padding-right: 15px;
  }
}

@media screen and (min-width: 992px) {
  .pl-md-0 {
    padding-left: 0px;
  }
  .pr-md-0 {
    padding-right: 0px;
  }
}

@media screen and (min-width: 574px) and (max-width: 767px) {
  .start-date-container {
    margin-bottom: 10px;
  }
  .end-date-container {
    margin-bottom: 10px;
  }
  .transactions {
    .statement {
      display: flex;
      justify-content: flex-end;
    }
  }
}
