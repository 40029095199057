$midnight-08: rgba(30, 42, 52, 0.8);
$full-black-006: rgba(0, 0, 0, 0.06);
$black-pearl: #1e2a32;
$pale-cornflower-blue: #b2d7f8;
$gainsboro: #d8d8d8;
$full-white: #fff;
$picton-blue: #619ecc;
$light-grey: #d0d0d0;
$persian-green: #00a19a;
$steel-blue: #4d8bbb;
$sapphire: #053b63;
$cinnabar: #eb5835;
$raven: #6d757b;

.pl-2 {
  padding-left: 0.5rem;
}
.pr-2 {
  padding-right: 0.5rem;
}

.pl-4 {
  padding-left: 1rem;
}
.pr-4 {
  padding-right: 1rem;
}

.w-50-percent {
  width: 50% !important;
}
.w-25-percent {
  width: 25% !important;
}
.justify-end {
  justify-content: flex-end !important;
}
.underline {
  text-decoration: underline;
}

.hover-no-underline {
  text-decoration: underline;
}

.hover-no-underline:hover {
  text-decoration: none;
}

.link {
  color: #619ecc !important;
}

.pb-0-important {
  padding-bottom: 0px !important;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mr-4 {
  margin-right: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-26-px {
  margin-bottom: 26px;
}
.pb-26-px {
  padding-bottom: 26px;
}

.px-2-important {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-0-important {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.pr-4-important {
  padding-right: 1rem !important;
}

.py-2-important {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.h-35px-important {
  height: 35px !important;
}

.lh-35px-important {
  line-height: 35px !important;
}

.text-14-important {
  font-size: 14px !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
