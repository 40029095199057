$midnight-08: rgba(30, 42, 52, 0.8);
$full-black-006: rgba(0, 0, 0, 0.06);
$black-pearl: #1e2a32;
$pale-cornflower-blue: #b2d7f8;
$gainsboro: #d8d8d8;
$full-white: #fff;
$picton-blue: #619ecc;
$light-grey: #d0d0d0;
$persian-green: #00a19a;
$steel-blue: #4d8bbb;
$sapphire: #053b63;
$cinnabar: #eb5835;
$raven: #6d757b;

.rc-pagination li {
  min-width: 50px;
  min-height: 50px;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.prev-button-wrapper {
  background-color: white;
  min-height: 50px;
  min-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 16px;
  border: "1px solid #d9d9d9";
}

.next-button-wrapper {
  background-color: white;
  min-height: 50px;
  min-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 16px;
  border: 1px solid #d9d9d9;
}

.rc-pagination-prev.rc-pagination-disabled .prev-button-wrapper {
  background-color: rgba(255, 255, 255, 0.4);
  color: rgba(0, 0, 0, 0.2);
}

.rc-pagination-next.rc-pagination-disabled .next-button-wrapper {
  background-color: rgba(255, 255, 255, 0.4);
  color: rgba(0, 0, 0, 0.2);
}

.pagination {
  color: $black-pearl;
  padding: 15px 20px;

  .paginate-email {
    display: flex;
    align-items: center;

    &:hover {
      color: $picton-blue;
      cursor: pointer;
    }

    &.text-right {
      justify-content: flex-end;
    }
  }

  .fa-arrow-left {
    margin-right: 15px;
  }

  .fa-arrow-right {
    margin-left: 15px;
  }
}
