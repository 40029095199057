$midnight-08: rgba(30, 42, 52, 0.8);
$full-black-006: rgba(0, 0, 0, 0.06);
$black-pearl: #1e2a32;
$pale-cornflower-blue: #b2d7f8;
$gainsboro: #d8d8d8;
$full-white: #fff;
$picton-blue: #619ecc;
$light-grey: #d0d0d0;
$persian-green: #00a19a;
$steel-blue: #4d8bbb;
$sapphire: #053b63;
$cinnabar: #eb5835;
$raven: #6d757b;

.doughnut-panel-container {
  display: flex;
  max-width: 100%;
}

.legend-container {
  width: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
}

.legend-container.legend-container-valuation {
  width: 50% !important;
}

.doughnut-panel-container-horizontal-legends {
  padding-top: 5px;
}

.doughnut-panel.doughnut-panel-loans {
  .doughnut-panel-bottom {
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: -10px;
  }
}

.doughnut-panel-container-mobile {
  display: none;
}

.doughnut-panel-title {
  background: linear-gradient(180deg, #024679 0%, #1a5d8f 100%);
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 10px;
  padding-right: 10px;
}

.doughnut-panel-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.portfolio-doughnut-charts {
  display: none;
}

.portfolio-doughnut-charts-mobile {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  overflow: hidden;
}

.portfolio-breakdown-table {
  th,
  td {
    padding-block: 27px !important;
  }
}

.portfolio-valuation-pie-item-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 260px;
}

.portfolio-breakdown-table-min-height {
  min-height: 259px;
}

.portfolio-breakdown-table-min-height.portfolio-breakdown-table-min-height-no-msg {
  min-height: 286px;
}

.portfolio-valuation-pie-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}

.portfolio-valuation-pie-wrapper {
  display: block;
}

.portfolio-valuation-pie-left {
  width: 42%;
}

.portfolio-valuation-pie-right {
  width: 58%;
  display: flex;
  flex-wrap: wrap;
}

.doughnut-panel-title.doughnut-panel-title-valuation {
  padding-block: 8px;
}

.legends-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.insights-autolend {
  table,
  th,
  td {
    border: solid 1px #f2f2f2;

    table-layout: auto;
    white-space: nowrap;
  }
}

.insights-autolend .sub-heading th {
  background: #4d8bbb;
  background: #bdbdbd;
  background: #f2f2f2;
  background: #fff;
  color: #4d8bbb !important;
}

.legend-text-container:hover {
  font-weight: bold !important;
}

.line-chart-margin {
  margin-top: 10px;
}

.valuation-tile-mobile {
  width: 30%;
  border-radius: 5px;
  text-align: center;
  background-image: linear-gradient(180deg, #024679 0%, #1a5d8f 100%);
  padding-bottom: 15px;
  border-bottom: 2px solid #ffffff;
  margin-bottom: 20px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.12);
}

.valuation-tile-mobile h5 {
  color: #fff;
  font-size: 20px;
  padding-top: 15px;
  padding-bottom: 10px;
}

.valuation-tile-mobile p {
  margin-bottom: 10px;
  color: #fff;
}

@media screen and (min-width: 768px) {
  .doughnut-panel.doughnut-panel-loans {
    .doughnut-panel-container {
      padding-top: 15px;
    }
  }
  .loan-chart-mt-12 {
    margin-top: 3rem;
  }
  .doughnut-panel-container-mobile {
    display: none;
  }

  .doughnut-panel-container-inner {
    width: 90%;
  }

  .doughnut-panel.doughnut-panel-loans {
    min-height: 372px;
    max-height: 372px;
  }
  .loan-chart-mt-12 {
    margin-top: 3rem;
  }
  .doughnut-panel-container-horizontal {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .doughnut-panel-container-horizontal-legends {
    padding: 20px;
    min-width: 60%;
  }
  .portfolio-doughnut-charts-mobile {
    display: none;
  }
  .portfolio-doughnut-charts {
    display: flex;
    flex-wrap: wrap;

    .doughnut-panel-container-horizontal-chart {
      min-height: 300px;
    }
  }
}

@media screen and (min-width: 850px) {
  .doughnut-panel-container-horizontal-legends {
    padding: 50px;
    min-width: 60%;
  }

  .doughnut-panel-container-inner {
    width: 85%;
  }
}

@media screen and (min-width: 900px) {
  .doughnut-panel-container-inner {
    width: 80%;
  }
}

@media screen and (min-width: 940px) {
  .doughnut-panel-container-inner {
    width: 75%;
  }
}

@media screen and (min-width: 992px) {
  .doughnut-panel-title {
    min-height: 13%;
    height: 13%;
  }
  .doughnut-panel-container {
    display: flex;

    padding-top: 12px;
    padding-bottom: 12px;
  }
  .doughnut-panel-bottom {
    min-height: 12%;
    height: 54px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 10px;
    padding-right: 10px;
  }

  .doughnut-panel.doughnut-panel-loans {
    min-height: 372px;
    max-height: 372px;

    .doughnut-panel-container {
      padding-left: 25px;
      padding-right: 25px;
    }
    .doughnut-panel-container,
    .doughnut-panel-title,
    .doughnut-panel-bottom {
      height: auto;
      min-height: auto;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .doughnut-panel-container-horizontal {
    display: flex;
    width: 100%;

    padding-left: 50px;
    padding-right: 50px;

    .doughnut-panel-container-horizontal-chart {
    }
    .doughnut-panel-container-horizontal-legends {
      padding: 50px;
      min-width: 70%;
    }
  }

  .line-chart-margin {
    margin-top: 50px;
  }
  .insights-autolend {
    table,
    th,
    td {
      border: solid 1px #f2f2f2;
      table-layout: fixed;
    }
  }
}

@media screen and (min-width: 1024px) {
  .legend-container {
    width: 33.33%;
    padding: 5px;
    display: flex;
    align-items: center;
  }
  .doughnut-panel-title {
    min-height: 15%;
    height: 15%;
  }

  .doughnut-panel.doughnut-panel-loans {
    min-height: 393px;
    max-height: 393px;

    .doughnut-panel-container {
      padding-left: 25px;
      padding-right: 25px;
    }
    .doughnut-panel-container,
    .doughnut-panel-title,
    .doughnut-panel-bottom {
      height: auto;
      min-height: auto;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .doughnut-panel-container-horizontal {
    padding-bottom: 25px;
  }

  .doughnut-panel-container {
    display: flex;

    padding-top: 25px;
  }
  .doughnut-panel-bottom {
    min-height: 10%;
    height: 45px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (min-width: 1000px) and (max-height: 720px) {
  .chart-padding-height {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media screen and (min-width: 768px) and (max-width: 890px) {
  .portfolio-breakdown-table-insights {
    min-height: 372px;
    max-height: 372px;
  }
}

@media screen and (min-width: 768px) and (max-width: 866px) {
  .portfolio-breakdown-table {
    th {
      padding-block: 19px !important;
    }
  }
}

@media screen and (min-width: 574px) and (max-width: 767px) {
  .doughnut-panel-container-horizontal
    .doughnut-panel-container-horizontal-chart {
    width: 38%;
  }

  .doughnut-panel-container-horizontal
    .doughnut-panel-container-horizontal-legends {
    width: 62%;
  }

  .doughnut-panel.doughnut-panel-loans {
    .doughnut-panel-container .doughnut-panel-container-inner {
      display: none;
    }
    .doughnut-panel-bottom {
      display: none;
    }

    .doughnut-panel-container-mobile {
      display: flex;
      .doughnut-panel-container-mobile-chart {
        width: 38%;
      }
      .doughnut-panel-side-mobile {
        width: 62%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }
}

@media screen and (min-width: 450px) and (max-width: 573px) {
  .doughnut-panel-loans {
    .doughnut-panel-container {
      padding: 30px;
    }
  }
}

@media screen and (max-width: 1080px) {
  .portfolio-valuation-pie-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
  }

  .portfolio-valuation-pie-left {
    width: 45%;
    padding: 10px;
  }

  .portfolio-valuation-pie-right {
    width: 55%;
    display: block;
    flex-wrap: nowrap;
  }

  .portfolio-valuation-pie-right .legend-container {
    width: 100% !important;
  }
}

@media (max-width: 991px) {
  .portfolio-valuation-pie-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
  }

  .portfolio-valuation-pie-left {
    width: 40%;
    padding: 30px;
  }

  .portfolio-valuation-pie-right {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
  }
  .portfolio-valuation-pie-right .legend-container {
    width: 50% !important;
  }
  .dashboard-section-margin-responsive {
    margin-bottom: 53px;
  }
  .portfolio-valuation-pie-wrapper {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 890px) {
  .portfolio-valuation-pie-left {
    width: 40%;
    padding: 25px;
  }
}

@media screen and (max-width: 767px) {
  .doughnut-panel-container-mobile {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .portfolio-doughnut-charts-mobile .doughnut-panel-container {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .portfolio-valuation-pie-left {
    width: 40%;
    padding: 10px;
  }

  .account-summary-tile-mobile-wrapper .account-summary-tile-mobile h5 {
    // color: #fff;
    font-size: 18px;
    // padding-top: 15px;
  }
}

@media screen and (max-width: 700px) {
  .valuation-tile-mobile {
    width: 47%;
  }
}

@media screen and (max-width: 600px) {
  .portfolio-valuation-pie-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
  }

  .portfolio-valuation-pie-left {
    width: 45%;
    padding: 10px;
  }

  .portfolio-valuation-pie-right {
    width: 55%;
    display: block;
    flex-wrap: nowrap;
  }

  .portfolio-valuation-pie-right .legend-container {
    width: 100% !important;
  }
}

@media screen and (max-width: 573px) {
  .doughnut-panel select {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .doughnut-panel-container-horizontal-chart {
    width: 100%;
  }
  .portfolio-doughnut-charts-mobile .doughnut-panel-container {
    padding-block: 0px;
  }
  .doughnut-panel-container-horizontal-legends {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .doughnut-panel-loans .doughnut-panel-container {
    padding: 30px;
  }

  .doughnut-panel-container-horizontal-chart {
    padding: 30px;
  }

  .doughnut-panel-container.doughnut-panel-container-horizontal {
    display: flex;
    flex-direction: column;
  }
  .statement {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 16px;
  }

  .transaction-button {
    margin-top: 10px;
  }

  .transactions .d-flex.transactions-date-container {
    display: block !important;
  }

  .account-summary-tile-mobile-wrapper .account-summary-tile-mobile h5 {
    // color: #fff;
    font-size: 15px;
    // padding-top: 15px;
  }
}

@media screen and (max-width: 567px) {
  .portfolio-valuation-pie-left {
    width: 55%;
    padding: 10px;
  }

  .portfolio-valuation-pie-right {
    width: 45%;
  }

  .portfolio-valuation-pie-right .legend-container {
    width: 100% !important;
  }
}

@media screen and (max-width: 573px) {
  .doughnut-panel select {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .doughnut-panel-container-horizontal-chart {
    width: 100%;
  }
  .portfolio-doughnut-charts-mobile .doughnut-panel-container {
    padding-block: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .doughnut-panel-container-horizontal-legends {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .doughnut-panel-loans .doughnut-panel-container {
    padding: 30px;
  }

  .doughnut-panel-container-horizontal-chart {
    padding: 30px;
  }

  .doughnut-panel-container.doughnut-panel-container-horizontal {
    display: flex;
    flex-direction: column;
  }
  .legends-container {
    display: flex;
    flex-direction: column;
  }
  .legend-text {
    font-size: 14px !important;
  }

  .doughnut-panel-container-inner {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .account-summary-tile-mobile {
    width: 45%;

    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
    // padding-inline: 20px;
    // padding-inline: 2px;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.2);
    padding-bottom: 15px;
    border-bottom: 2px solid #ffffff;
    margin-bottom: 20px;
  }

  .account-summary-tile-mobile h5 {
    color: #fff;
    font-size: 14px;
    padding-top: 15px;
  }

  .account-summary-tile-mobile p {
    margin-bottom: 10px;
    color: #fff;
    font-size: 14px;
  }
  .account-summary-tile-mobile h5 {
    padding-bottom: 5px;
  }

  .valuation-tile-mobile {
    width: 47%;
    border-radius: 5px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.2);
    padding-bottom: 15px;
    border-bottom: 2px solid #ffffff;
    margin-bottom: 20px;
  }

  .valuation-tile-mobile h5 {
    color: #fff;
    font-size: 14px;
    padding-top: 15px;
  }

  .valuation-tile-mobile p {
    margin-bottom: 10px;
    color: #fff;
    font-size: 14px;
  }
  .valuation-tile-mobile h5 {
    padding-bottom: 5px;
  }

  .portfolio-valuation-pie-item {
    display: flex;
    flex-direction: column;
  }

  .portfolio-valuation-pie-left {
    width: 100%;
    padding: 20px;
  }

  .portfolio-valuation-pie-right {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .portfolio-valuation-pie-right .legend-container {
    width: 50% !important;
  }
}

@media screen and (max-width: 400px) {
  .account-summary-tile-mobile-wrapper .account-summary-tile-mobile {
    width: 47%;

    margin-left: 5px;
    margin-right: 5px;
    border-bottom: 2px solid #ffffff;
    padding-left: 1px;
    padding-right: 1px;
    margin-bottom: 10px;
  }

  .account-summary-tile-mobile-wrapper .account-summary-tile-mobile h5 {
    color: #fff;
    font-size: 14px;
    padding-top: 15px;
  }

  .account-summary-tile-mobile p {
    margin-bottom: 10px;
    color: #fff;
    font-size: 14px;
  }

  .valuation-tile-mobile {
    width: 47%;
    border-bottom: 2px solid #ffffff;
  }

  .valuation-tile-mobile h5 {
    color: #fff;
    font-size: 14px;
    padding-top: 15px;
  }

  .valuation-tile-mobile p {
    margin-bottom: 10px;
    color: #fff;
    font-size: 14px;
  }
}

@media screen and (max-width: 380px) {
  .legends-container {
    display: flex;
    flex-direction: column;
  }
  .legend-text {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 347px) {
  .account-summary-tile-mobile-wrapper .account-summary-tile-mobile h5 {
    color: #fff;
    font-size: 12px;
    padding-top: 15px;
  }

  .account-summary-tile-mobile-wrapper .account-summary-tile-mobile {
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 5px;
    padding-left: 0px;
    padding-right: 0px;
    width: 46%;
  }

  .valuation-tile-mobile {
    width: 46%;

    margin-left: 5px;
    margin-right: 5px;
    border-radius: 5px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .portfolio-valuation-pie-item {
    display: flex;
    flex-direction: column;
  }

  .portfolio-valuation-pie-left {
    width: 100%;
    padding: 20px;
  }

  .portfolio-valuation-pie-right {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .portfolio-valuation-pie-right .legend-container {
    width: 60% !important;
  }

  .portfolio-valuation-pie-right .legend-container p {
    padding-left: 15px !important;
  }
}

@media screen and (max-width: 300px) {
  .portfolio-valuation-pie-right .legend-container {
    width: 65% !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS */
  .chart-animated-icon {
    display: none;
  }
}
