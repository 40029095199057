$midnight-08: rgba(30, 42, 52, 0.8);
$full-black-006: rgba(0, 0, 0, 0.06);
$black-pearl: #1e2a32;
$pale-cornflower-blue: #b2d7f8;
$gainsboro: #d8d8d8;
$full-white: #fff;
$picton-blue: #619ecc;
$light-grey: #d0d0d0;
$persian-green: #00a19a;
$steel-blue: #4d8bbb;
$sapphire: #053b63;
$cinnabar: #eb5835;
$raven: #6d757b;

$imagesPath: "../../images";

form {
  .error {
    color: red !important;
  }

  .data-set {
    position: relative;

    input:focus + .password-strength {
      display: block;
    }

    .password-strength {
      position: absolute;
      //top: calc(100% - 91px);
      top: 73px;
      display: none;
      left: 0;
      background: #fff;
      padding: 10px 20px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      border: 1px solid rgb(168, 174, 178);
      z-index: 999;

      ul {
        margin-top: 5px;
      }

      p {
        padding: 5px 0 5px;
      }

      .valid {
        color: #479e99;
      }

      .invalid {
        color: #da6142;

        &:before {
          background-image: url("#{$imagesPath}/password-invalid.png");
        }
      }

      li {
        position: relative;
        font-size: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        div {
          line-height: 1;
        }

        &:before {
          content: "";
          background: url("#{$imagesPath}/password-valid.png") no-repeat;
          background-size: contain;
          height: 15px;
          position: relative;
          top: 0px;
          margin-right: 7px;
          width: 15px;
          display: inline-block;
        }
      }
    }
  }
}

#self-select-form {
  .row {
    display: flex;
    align-items: center;
  }
  .sub-text {
    font-size: 14px;
    // font-weight: bold;
    padding-bottom: 0px;
  }
  .alwaysOnInvestmentValue {
    font-weight: 500;
  }

  .alwaysOnAllocationText {
    padding-bottom: 0px;
  }
}
