$midnight-08: rgba(30, 42, 52, 0.8);
$full-black-006: rgba(0, 0, 0, 0.06);
$black-pearl: #1e2a32;
$pale-cornflower-blue: #b2d7f8;
$gainsboro: #d8d8d8;
$full-white: #fff;
$picton-blue: #619ecc;
$light-grey: #d0d0d0;
$persian-green: #00a19a;
$steel-blue: #4d8bbb;
$sapphire: #053b63;
$cinnabar: #eb5835;
$raven: #6d757b;

.panel.panel-link-box {
  cursor: pointer;
}

.panel.panel-link-box.panel-link-box-wrapper {
  display: block;
}

.panel.panel-link-box:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.panel.panel-link-box:active {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.panel-2fa {
  overflow: visible !important;
}
.panel.box-un-read {
  background-color: $full-white;
  // color: #fff;
}
.panel.doughnut-panel {
  p {
    font-weight: 500;
    font-size: 18px;
    text-align: right;
  }
}

.panel .path {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: dash;
  animation-iteration-count: 2;
  animation-duration: 1.5s;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

.panel-enter .path {
  animation: dash-hover;
  animation-iteration-count: 2;
  animation-duration: 1.5s;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes dash-hover {
  to {
    stroke-dashoffset: 0;
  }
}
