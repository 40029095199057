@import "./vendor/bootstrap-custom";
@import "./base/proplend";
@import "./components/loading";
@import "./components/forms";
@import "./components/chart";
@import "./components/datepicker";
@import "./components/insights";
@import "./components/portfolio-break-down-mobile";
@import "./components/general";
@import "./components/panel";
@import "./components/pagination";
@import "./components/tables";

$midnight-08: rgba(30, 42, 52, 0.8);
$full-black-006: rgba(0, 0, 0, 0.06);
$black-pearl: #1e2a32;
$pale-cornflower-blue: #b2d7f8;
$gainsboro: #d8d8d8;
$full-white: #fff;
$picton-blue: #619ecc;
$light-grey: #d0d0d0;
$persian-green: #00a19a;
$steel-blue: #4d8bbb;
$sapphire: #053b63;
$cinnabar: #eb5835;
$raven: #6d757b;
$light-grey-2: #e0e0e0;

.align-middle {
  vertical-align: middle !important;
}

.text-area-125-px-height {
  height: 125px;
}

.text-area-100-px-height {
  height: 100px;
}

.text-area-100-px-height-imp {
  height: 100px !important;
}

.align-left {
  text-align: left;
}

.ml-neg-5px {
  margin-left: -5px;
}
.justify-between {
  justify-content: space-between;
}

.font-awesome-font-size-18 {
  font-size: 18px;
}

.font-awesome-font-size-20 {
  font-size: 20px;
}

.markets-text {
  margin-bottom: 10px;
  padding: 0px;
}

.small-terms-container {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
}

.modal-full-width-no-padding {
  width: 100%;
  padding: 0;
}

.error-modal {
  width: 100%;
  padding: 0;
}

.mail-badge {
  margin-top: -5px;
}

.w-50-percent {
  width: 50%;
}

.w-800-px {
  width: 800px;
}

.h-125-px {
  height: 125px !important;
}

.mobile-signout-button {
  margin-left: 4px;
  margin-right: 10px;
}

.mobile-account-type-switch-text {
  margin-left: 2px;
}

.mobile-account-type-switch-link {
  display: flex;
  align-items: center;
  color: #4592ec;
}

.switch-to-account-types-wrapper {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #d3d3d3;
  margin-top: 25px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.loans-header {
  .tabs-wrapper {
    border-bottom: 1px solid $gainsboro;
  }

  &.loans-header-mt--30px {
    margin-top: -30px;
  }
}

.out-dated-browser-text-wrapper {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 4rem;
  margin-bottom: 4rem;
  margin-left: 2rem;
  color: $cinnabar;
}

.auto-lend-header-title,
.auto-lend-read-more-text {
  p {
    strong {
      font-size: 18px;
    }
  }
}

.font-size-18px {
  font-size: 18px;
}

.mt-9px {
  margin-top: 9px;
}

.pt-9px {
  padding-top: 9px;
}

.body-loader {
  min-height: 676px;
  padding: 57px 0 42px;
  // padding: 0px;

  @media screen and (max-width: 1300px) {
    min-height: 700px;
  }

  @media screen and (max-width: 1024px) {
    min-height: 724px;
  }

  @media screen and (max-width: 767px) {
    min-height: 690px;
    .body-loader {
      padding: 30px 0;
    }
  }
}

.page {
  transition: all ease-in 200ms;
}

.green {
  color: $persian-green;
}

.yellow {
  color: $dark-orange;
}

.global-down-notification {
  border-bottom: 1px solid $light-grey-2 !important;
}

.warning-red {
  color: $cinnabar !important;
}

.generic-modal {
  p {
    padding-bottom: 10px;
  }
}

.general-modal-completion-item-right {
  padding-bottom: 0px;
  width: 95%;
}

.general-modal-completion-item-left {
  width: 6%;
}

.general-status {
  display: inline-block;
  width: 14px;
  vertical-align: middle;
  margin-bottom: -3px;
  border-radius: 50%;
  height: 14px;
  background: $persian-green;
  margin-right: 5px;
  flex-shrink: 0;
  position: relative;

  &.grey {
    background: $grey-chateau;
  }

  &.orange {
    background: $dark-orange;
  }

  &.blue {
    background: $dark-cerulean;
  }

  &.red {
    background: $cinnabar;
  }
}

.general-modal-tooltip-wrapper {
  position: relative;
  display: inline-block;
  .tooltip {
    position: absolute;
    top: 100%;
    width: 168px;
    display: none;
    padding: 15px 5px;
    line-height: 20px;
    text-align: center;
    margin-top: 9px;
    left: 300%;
    margin-left: -84px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid $gainsboro-2;
    background-color: $coconut;
    color: $black-pearl;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 99999 !important;
  }

  &:hover .tooltip {
    display: block;
  }

  .tooltip:before {
    content: "";
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid $gainsboro-2;
    width: 14px;
    height: 14px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background: $full-white;
    position: absolute;
    left: 16%;
    top: -5px;
    margin-left: -7px;
  }

  .tooltip:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 18px;
    background: $full-white;
  }
}

.general-status .tooltip {
  position: absolute;
  top: 100%;
  width: 168px;
  display: none;
  padding: 15px 5px;
  line-height: 20px;
  text-align: center;
  margin-top: 9px;
  left: 50%;
  margin-left: -84px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid $gainsboro-2;
  background-color: $coconut;
  color: $black-pearl;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 99999 !important;
}

.general-status:hover .tooltip {
  display: block;
}

.general-status .tooltip:before {
  content: "";
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid $gainsboro-2;
  width: 14px;
  height: 14px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background: $full-white;
  position: absolute;
  left: 50%;
  top: -5px;
  margin-left: -7px;
}

.general-status .tooltip:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 18px;
  background: $full-white;
}

.mr-20-px {
  margin-right: 20px;
}

.mb-30-px {
  margin-bottom: 30px;
}

.mt-30-px {
  margin-top: 30px;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mt-0 {
  margin-top: 0px;
}

.mt-0-important {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-0-important {
  margin-bottom: 0px !important;
}

.pt-0 {
  padding-top: 0px;
}

.pt-0-important {
  padding-top: 0px !important;
}

.text-disabled {
  // color: $light-grey-2 !important;
  color: #6d757b !important;
  // display: none;
}

.select-disabled {
  // display: none;
  background: rgb(238, 237, 235) !important;
  border-color: $light-grey-2 !important;
  // color: $light-grey-2 !important;
  color: #6d757b !important;
  option {
    color: $light-grey-2 !important;
  }
}

.input-disabled {
  background: rgb(238, 237, 235) !important;
  border-color: $light-grey-2;
  color: #6d757b !important;
}

.always-on-modal {
  h3 {
    line-height: 1.2;
  }
}

.selectedCertRadio {
  height: 15px !important;
  width: 18px !important;
}

.width-auto {
  width: auto !important;
}

.height-auto {
  height: auto !important;
}

.self-cert-tab {
  .checkbox-radio label span::before {
    border-radius: 50%;
  }

  .checkbox-radio label span::after {
    border-radius: 50% !important;
  }

  .selectedCertTextInput {
    position: static !important;
    max-width: 100% !important;
  }
  input {
    height: 32px;
    background: $full-white;
  }

  ul,
  li {
    list-style: disc;
    // margin: auto;
    padding: revert;
    margin-bottom: 8px;
    margin-left: 5px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    font-weight: revert;
    font-size: revert;
    line-height: revert;
    margin: revert;
    padding: revert;
  }

  input:disabled {
    background: rgb(238, 237, 235);
    border-color: $light-grey-2;
    color: #6d757b !important;
  }

  .data-set {
    padding-bottom: 15px;
    padding-top: 0px;
  }

  // .checkbox label span::before {
  //   top: 4px;
  //   bottom: 39px;
  // }

  // p {
  //   margin-top: 0px;
  //   margin-bottom: 0px;
  // }
  // input [type="radio"] {
  //   height: 17px !important;
  //   width: 17px !important;
  // }
}

.selectedCertRadioText {
  // color: #6d757b !important;
  * {
    color: #6d757b !important;
    // color: $light-grey-2 !important;
    // color: #1e2a32 !important;
  }
}

.self-cert-label {
  display: inline !important;
  text-transform: none;
}
.showInline {
  display: inline !important;
  * {
    display: inline !important;
  }
}

.body-settings {
  overflow: visible !important;
}

.page {
  min-height: 80vh;
}

.single-loan-table-wrapper-wealth {
  .panel.loan-list {
    margin-top: 0px !important;
  }
  .tabs-row {
    margin-bottom: 10px !important;
  }
}

.resend-button {
  cursor: pointer;
  text-decoration: underline;
}

.resend-button:hover {
  color: black !important;
}

.show-more-less {
  color: $sapphire;
}

.breakdown-vauation-min-height {
  min-height: 300px;
}

.BaseBadge-badge {
  font-size: 8px !important;
}

.MuiLinearProgress-bar {
  background: linear-gradient(to right, #024679 0%, #1a5d8f 100%) !important;
}

.all-properties-select {
  width: 40% !important;
  margin-left: 16px;
}

.all-categories-select {
  width: 25% !important;
  margin-left: 16px;
}

.all-messages-select {
  width: 25% !important;
  margin-left: 16px;
}

.message-filters {
  display: flex;
  justify-content: flex-end;
}

.messaging {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message-filters select {
  text-overflow: ellipsis;
}

.text-messaging {
  font-size: 24px;
}

.show-more-less {
  color: $sapphire;
}

.breakdown-vauation-min-height {
  min-height: 300px;
}

.BaseBadge-badge {
  font-size: 8px !important;
}

.MuiLinearProgress-bar {
  background: linear-gradient(to right, #024679 0%, #1a5d8f 100%) !important;
}

.rc-pagination::after {
  display: none;
}

.message-mobile-wrapper.panel {
  background-color: $gainsboro;
}

.message-mobile-wrapper p {
  font-weight: 400;
}

.message-mobile-item {
  display: flex;
  justify-content: space-between;
}

.message-icon-update {
  display: flex;
}

.message-mobile-type {
  padding-left: 10px;
}

.invested-properties-table-mobile-wrapper,
.auto-lend-table-mobile-wrapper {
  background-color: $full-white;
  border-radius: 10px;
  border-top: 5px solid $sapphire;
  width: 100%;
  padding: 20px 10px 10px 10px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.12);
}

.invested-properties-table-mobile-wrapper {
  border-top: none;
  padding: 0px 0px 0px 0px;
  overflow: hidden;
  border-radius: 5px;
}

.invested-properties-header-wrapper {
  text-align: left;
  padding-block: 10px;
  padding-left: 10px;
}

.invested-properties-header-wrapper h5 {
  font-weight: 500 !important;
}

.invested-properties-table-spacing {
  padding: 0px 10px 10px 10px;
}

.invested-properties-wrapper,
.auto-lend-wrapper {
  margin-bottom: 10px;
}

.invested-properties-item-wrapper,
.auto-lend-item-wrapper {
  width: 100%;
}

.invested-properties-button-wrapper {
  width: 100%;
}

.invested-properties-button {
  width: 100%;
  background-color: $cinnabar;
  color: $full-white;
  border: none;
  padding: 15px 0px;
  font-size: 16px;
  border-radius: 0px !important;
}

.auto-lend-wrapper h5 {
  color: $sapphire !important;
  font-weight: 500;
}

.invested-properties-sub-item-wrapper,
.auto-lend-sub-item-wrapper {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}

.invested-properties-sub-item-wrapper {
  color: $raven;
}

.hid-hoder.hid-hoder-remove-spacing {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.MuiTabs-root a {
  font-size: 16px !important;
}

.slick-slide {
  padding-left: 50px !important;
}

.line-height-sm {
  line-height: 0.8 !important;
}

.autolend-text-wrapper {
  display: flex !important;
  opacity: 0;
  max-height: 0;
  transition: padding 300ms ease-in-out, opacity 500ms ease-in-out,
    max-height 500ms ease;

  padding-top: 0px !important;
  padding-bottom: 0px !important;

  &.display-flex {
    display: flex !important;
    opacity: 1;
    max-height: 150vh;
    padding-top: 20px !important;
    padding-bottom: 30px !important;
  }
}

.autolend-text-wrapper ul {
  list-style-type: disc;
  padding-left: 15.2px;

  @media screen and (min-width: 1024px) {
    // padding-left: 40px;
    // margin-left: 5px;
  }
}

// .autolend-text-wrapper li::before {
//   content: "\2B24";
//   font-size: 7px;
//   margin-right: 10px;
//   vertical-align: middle;
// }

.transfer-panel li {
  list-style-type: disc;
  margin-left: 16px;
  margin-bottom: 10px;
}

.transfer-panel li ul {
  margin-block: 15px;
  margin-left: 10px;
}

.transfer-panel li ul li {
  list-style-type: circle;
  list-style-type: "\2015\0020 ";
  margin-left: 16px;
}

.slider-modal-portal {
  position: fixed;
  background: transparent;
  z-index: 99999999999;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}

.slider-modal-overlay {
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  box-shadow: 0 1px 2px $full-black-006;
  border-radius: 4px;
  background-color: $midnight-08;
  padding: 10px;
}

.slider-modal {
  width: auto;
  height: auto;
}

.country-phone-input input {
  width: 100% !important;
  padding-block: 20px;
}

.filter-row-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .summary-button-inside-filter-row {
    height: 38px;
    line-height: 36px;
    margin: 10px 0px;
    padding: 0px 12px;
    display: inline-block;
    color: $black-pearl;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 4px;
    border: 1px solid $gainsboro;
    background-color: $full-white;
    &:hover {
      background-color: $picton-blue;
      text-decoration: none;
      color: $full-white;
      border-color: $picton-blue;
    }
    &:disabled {
      pointer-events: none !important;
      background-color: $light-grey !important;
    }
  }
}

.filter-row-container.filter-row-container-end {
  justify-content: flex-end;
}

.detailed-button-container {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.detailed-button {
  height: 38px;
  line-height: 36px;
  padding: 0 12px;
  display: inline-block;
  color: $black-pearl;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 4px;
  border: 1px solid $gainsboro;
  background-color: $full-white;
  &:disabled {
    pointer-events: none !important;
    background-color: $light-grey !important;
  }
}

.detailed-button:hover {
  background-color: $picton-blue;
  text-decoration: none;
  color: $full-white;
  border-color: $picton-blue;
}

.message-icon {
  display: none;
}

.trigger {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 100%;
  white-space: nowrap;
  padding-right: 35px !important;
}

.user {
  max-width: 100%;
}

#loans-footer {
  background-color: #dedede;
}

.rollover-form {
  .warning {
    color: $cinnabar !important;
  }
}

.outdated-browser {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 4rem;
  margin-left: 2rem;
  color: #eb5835;
}

@media screen and (min-width: 1500px) {
  .user {
    max-width: 300px;
  }
}

@media screen and (max-width: 1400px) {
  .header-dashboard {
    display: none;
  }
}

@media screen and (max-width: 1300px) {
  .header .main-nav > ul a {
    font-size: 14px;
  }
  .lender-id-info {
    display: none;
  }
  .message-icon {
    display: block;
    float: left;
    color: $black-pearl;
    border-left: 1px solid $light-grey;
    height: 26px;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    margin: 23px 0 0 18px;
    padding-left: 19px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .message-icon::after {
    content: "";
    clear: both;
    display: table;
  }
}

@media screen and (max-width: 1165px) {
  .self-cert-tabs {
    display: flex;
    flex-wrap: wrap;
    .btn.btn-secondary {
      height: auto;
      width: 49%;
      margin-bottom: 8px;
      // margin-right: 0.5px;
    }

    .btn.btn-primary {
      height: auto;
      width: 49%;
      margin-bottom: 8px;
      // margin-right: 0.5px;
    }
  }
}

@media screen and (max-width: 1140px) {
  .header .main-nav > ul a {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 1023px) {
  //Menu icon

  .hamburger .line {
    width: 32px;
    height: 4px;
    background-color: #000;
    display: block;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .hamburger:hover {
    cursor: pointer;
  }

  #hamburger-1.is-active .line:nth-child(2) {
    opacity: 0;
  }

  #hamburger-1.is-active .line:nth-child(1) {
    -webkit-transform: translateY(9px) rotate(45deg);
    -ms-transform: translateY(9px) rotate(45deg);
    -o-transform: translateY(9px) rotate(45deg);
    transform: translateY(9px) rotate(45deg);
  }

  #hamburger-1.is-active .line:nth-child(3) {
    -webkit-transform: translateY(-9px) rotate(-45deg);
    -ms-transform: translateY(-9px) rotate(-45deg);
    -o-transform: translateY(-9px) rotate(-45deg);
    transform: translateY(-9px) rotate(-45deg);
  }

  //Menu icon over

  .header.header-menu-open {
    width: 100%;
    z-index: 12;
  }

  .header .message-icon {
    position: relative;
    z-index: 12;
  }

  .header .logo {
    position: relative;
    z-index: 12;
  }

  .header.header-menu-open .message-icon {
    position: fixed;
    left: 190px;
  }

  .header.header-menu-open .logo {
    position: fixed;
  }

  .header.header-menu-open .menu-trigger {
    position: fixed;
  }

  .main-nav::before {
    content: "";
    background-color: rgba(255, 255, 255, 0.4);
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100vh;
    z-index: -1;
  }

  .main-nav.mobile-show::before {
    content: "";
    background-color: rgba(255, 255, 255, 0.9);

    -webkit-filter: blur(10px);
    filter: blur(10px);
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100vh;
    z-index: -1;
  }

  .dropdown-hide-small {
    display: none;
  }

  .hamburger .line {
    width: 32px;
    height: 4px;
    background-color: #000;
    display: block;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .hamburger:hover {
    cursor: pointer;
  }

  #hamburger-1.is-active .line:nth-child(2) {
    opacity: 0;
  }

  #hamburger-1.is-active .line:nth-child(1) {
    -webkit-transform: translateY(9px) rotate(45deg);
    -ms-transform: translateY(9px) rotate(45deg);
    -o-transform: translateY(9px) rotate(45deg);
    transform: translateY(9px) rotate(45deg);
  }

  #hamburger-1.is-active .line:nth-child(3) {
    -webkit-transform: translateY(-9px) rotate(-45deg);
    -ms-transform: translateY(-9px) rotate(-45deg);
    -o-transform: translateY(-9px) rotate(-45deg);
    transform: translateY(-9px) rotate(-45deg);
  }

  .header.header-menu-open {
    width: 100%;
    z-index: 12;
  }

  .header .message-icon {
    position: relative;
    z-index: 12;
  }

  .header .logo {
    position: relative;
    z-index: 12;
  }

  .header.header-menu-open .message-icon {
    position: fixed;
    left: 190px;
  }

  .header.header-menu-open .logo {
    position: fixed;
  }

  .header.header-menu-open .menu-trigger {
    position: fixed;
  }

  .main-nav {
    pointer-events: none;
  }

  .main-nav.mobile-show {
    pointer-events: all;
  }

  .main-nav::before {
    content: "";
    background-color: rgba(255, 255, 255, 0.9);

    -webkit-filter: blur(10px);
    filter: blur(10px);
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100vh;
    z-index: -1;
  }

  .user {
    .trigger {
      display: none !important;
    }
  }

  .hid-hoder {
    p {
      font-size: 22px;
    }
  }

  // .self-cert-tabs {
  //   .btn.btn-secondary {
  //     height: auto;
  //   }
  // }
  .self-cert-tabs {
    display: flex;
    flex-wrap: wrap;
    .btn.btn-secondary {
      height: auto;
      width: 49%;
      margin-bottom: 8px;
      // margin-right: 0.5px;
    }

    .btn.btn-primary {
      height: auto;
      width: 49%;
      margin-bottom: 8px;
      // margin-right: 0.5px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .user {
    max-width: 250px;
  }
  .header .main-nav .user .trigger:after {
    position: absolute;
    height: 100%;
    right: 12px;
    top: 0px;
  }
  .MuiTab-root {
    font-size: 16px !important;
  }
  .table.sm.portfolio-breakdown-table-completed-loans {
    table td {
      width: 25% !important;
    }
  }
  .hideOnDesktop {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .panel-small-important {
    padding: 20px 30px 20px 25px !important;
  }

  .panel-small-important p {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) {
  .mt-4-md {
    margin-top: 1rem;
  }

  // .self-cert-tabs {
  //   .btn.btn-secondary {
  //     height: auto;
  //   }
  // }
  .self-cert-tabs {
    display: flex;
    flex-wrap: wrap;
    .btn.btn-secondary {
      height: auto;
      width: 49%;
      margin-bottom: 8px;
      // margin-right: 0.5px;
    }
    .btn.btn-primary {
      height: auto;
      width: 49%;
      margin-bottom: 8px;
      // margin-right: 0.5px;
    }
  }
  .self-cert-confirmation-row {
    flex-wrap: nowrap;
  }

  .appropriateness-test-row {
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 700px) {
  .auto-lend-column,
  .target-column,
  .expected-column,
  .current-column {
    width: 150px;
    min-width: 150px;
  }
  .loans-column {
    width: 300px;
    min-width: 300px;
  }
  .loans-column-1 {
    width: 150px;
    min-width: 150px;
  }
  .loans-column-2 {
    width: 150px;
    min-width: 150px;
  }
  .account-summary-tile-mobile {
    width: 45%;
  }

  .self-cert-tabs {
    display: flex;
    flex-wrap: wrap;
    .btn.btn-secondary {
      height: auto;
      width: 49%;
      margin-bottom: 8px;
      // margin-right: 0.5px;
    }
    .btn.btn-primary {
      height: auto;
      width: 49%;
      margin-bottom: 8px;
      // margin-right: 0.5px;
    }
  }

  .general-modal-completion-item-right {
    width: 93%;
  }

  .general-modal-completion-item-left {
    width: 7%;
  }
}

@media screen and (max-width: 500px) {
  .message-mobile-wrapper p {
    font-size: 14px !important;
  }

  .message-mobile-wrapper span {
    font-size: 14px !important;
  }

  .valuation-container p {
    font-size: 24px !important;
  }

  .valuation-container span {
    font-size: 24px !important;
  }

  .message-icon {
    display: none;
  }
  .isa-wrapper p {
    word-wrap: break-word;
  }

  .ml-20-mobile {
    margin-left: 15px;
    line-height: 1 !important;
  }

  .mr-20-mobile {
    margin-right: 15px;
    line-height: 1 !important;
  }
  .sm-none {
    display: none;
  }

  .self-cert-tabs {
    display: flex;
    flex-wrap: wrap;
    .btn.btn-secondary {
      height: auto;
      width: 100%;
    }

    .btn.btn-primary {
      height: auto;
      width: 100%;
    }
  }
}

.header h1::after {
  content: "";
  clear: both;
  display: table;
}

.bact-to-dashboard:hover {
  color: $steel-blue;
}

:focus:not(:focus-visible) {
  outline: 0;
}

.modal-panel {
  max-height: 100%;
}

.box b {
  font-weight: bold;
}

.box {
}

.cursor-pointer {
  cursor: pointer !important;
}

.form-edit-container {
  display: flex;
  justify-content: flex-end;
}

.form-edit-button {
  color: red;
  text-align: right;
  background-color: transparent;
  outline: none;
  border: none;
}

.form-edit-button:hover {
  text-decoration: underline;
}
.modal-form {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.envelope-icon {
  font-size: 20px;
  color: $sapphire;
  margin-left: 7px;
  transition: none;
  position: relative;

  span {
    font-size: 11px;
    text-align: center;
    width: 15px;
    line-height: 1;
    height: 15px;
    position: absolute;
    top: -6px;
    right: -8px;
    background: rgba(#3686f7, 0.8);
    padding-top: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    letter-spacing: 0;
    color: $full-white;
  }
}

.self-cert-tabs {
  justify-content: space-between;
  margin-bottom: 30px;
}

.self-cert-label {
  input {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.footer {
  @media (min-width: 992px) {
    margin-top: 40px;
  }
}

.the-closer {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  background: none;
  border: 0;
}

.self-select {
  @media (max-width: 1064px) and (min-width: 1064px) {
    .header h1 {
      display: none !important;
    }
  }

  .selfselect-property {
    text-overflow: ellipsis;
    @media screen and (max-width: 400px) {
      option {
      }
    }
    option {
      text-overflow: ellipsis;
    }
  }

  .filter-row {
    text-align: left !important;
  }

  .first-filter {
    padding-left: 0px !important;
  }

  .filter-row button.disabled {
    pointer-events: none !important;
    background-color: $light-grey !important;
  }

  .filter-row button.current,
  .filter-row button:hover {
    background-color: $picton-blue;
    text-decoration: none;
    color: $full-white;
    border-color: $picton-blue;
  }

  .tranche-label {
    // font-weight: bold;
    position: relative;
  }

  .header-text {
    padding: 5px 0px 30px 0px;
  }

  .explaining-text {
    padding-top: 20px;
    padding-bottom: 30px;
    display: none;
    .col-md-6 {
      padding-left: 15px;
      @media screen and (min-width: 700px) {
        padding-left: 45px;
      }
    }
  }

  .explaining-text ul {
    // padding-top: 10px;
  }

  .explaining-text ul li {
    padding-top: 5px;
    padding-bottom: 5px;
    // text-transform: capitalize;
  }

  .explaining-text ul {
    li::first-letter {
      text-transform: capitalize;
    }
  }

  .explaining-text ul li .indent {
    padding-left: 35px;
    min-height: 50px;
    height: 50px;
  }

  .explaining-text p {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 10px;
  }

  .risk-text {
    text-transform: capitalize;
    font-size: 12px;
  }

  .amend-text {
    font-size: 14px !important;
    font-weight: 600 !important;
    padding-top: 20px !important;
    padding-bottom: 0px !important;
  }

  .tranche-row {
    padding-top: 25px;
  }

  .modal-overlay .cat-box {
    width: 1050px !important;
  }

  .look-disabled {
    background-color: $light-grey !important;
  }

  .fa-window-close {
    position: absolute;
    float: right;
    width: 10px;
    top: 5px;
    right: 20px;
    font-size: 24px;
  }

  .overview-statement {
    display: none;
  }

  .detail {
    display: none;
  }

  .detail .table td {
    padding: 20px 20px 24px;
    vertical-align: middle;
    line-height: 18px;
    font-size: 14px;
  }

  .read-more {
    text-decoration: underline;
  }

  .warning {
    margin: 0;
    color: $cinnabar !important;
  }
}

.full-loader {
  .loader {
    height: 430px;
  }
}

@media screen and (max-width: 567px) {
  .modal-panel {
    max-height: 90%;
    overflow-y: scroll;
  }

  .modal-panel p {
    font-size: 14px !important;
  }

  .filter-row-container {
    .summary-button-inside-filter-row {
      font-size: 11px;
      height: 30px;
      line-height: 28px;
      padding: 0 7px;
    }
  }

  .container > .loans-header {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 450px) {
  .general-modal-completion-item-right {
    width: 90%;
  }

  .general-modal-completion-item-left {
    width: 10%;
  }
}

@media screen and (max-width: 380px) {
  .statement-startdate {
    margin-top: -10px;
  }

  .general-modal-completion-item-right {
    width: 90%;
  }

  .general-modal-completion-item-left {
    width: 10%;
  }
}

@media screen and (min-width: 567px) and (max-height: 600px) {
  .modal-panel {
    max-height: 90%;
    overflow-y: scroll;
  }
}

@media screen and (min-width: 567px) {
  .modal-panel {
    max-height: 100%;
    overflow-y: auto;
  }
  .slider-modal-overlay {
    padding: "30px";
  }
}

@media (hover: none) {
  .filter-row button:hover {
    background-color: $full-white;
    color: $black-pearl;
  }
  .filter-row .current:hover {
    background-color: $picton-blue;
    color: $full-white;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    //safari only styles
    .react-tooltip-outline {
      outline: none;
    }
  }
}
.react-tooltip-outline {
  outline: none;
  text-transform: none;
}

// @media (hover: none) {
//   .filter-row button:hover {
//     background-color: #fff;
//     color: #1e2a32;
//   }
// }
